import React from "react";
import walletIcon from "../images/img/dashboard/chart02.svg";
import fireIcon from "../images/img/dashboard/fire.svg";

const HomeOverview = ({ homeData }) => {
  return (
    <div className="container mx-auto p-4">
      {/* top view for today and all time income */}
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-lg font-bold text-white mb-0 inline-flex items-center">
          Earnings Overview{" "}
          <img src={fireIcon} width={20} className="ml-2" alt="Fire Icon" />
        </h5>
        <a className="text-blue-500 no-underline" href="/statistics">
          View report
        </a>
      </div>

      <div className="grid grid-cols-1 gap-4">
        <div
          className="shadow rounded-lg p-4"
          style={{ backgroundColor: "rgb(24 36 80)" }}
        >
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-400">Today</h6>
              <span className="text-2xl font-bold text-white">
                $
                {homeData.today_revenue > 0
                  ? parseFloat(homeData.today_revenue).toFixed(4)
                  : "0.0000"}
              </span>
            </div>
            <div>
              <img src={walletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </div>
        <div
          className="shadow rounded-lg p-4"
          style={{ backgroundColor: "rgb(24 36 80)" }}
        >
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-400">All Time</h6>
              <span className="text-2xl font-bold text-white">
                ${parseFloat(homeData.overview.total_earning).toFixed(4)}
              </span>
            </div>
            <div>
              <img src={walletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </div>
      </div>

      {/* captcha view for today and all time captchas */}
      <div className="flex justify-between items-center mb-4 mt-4">
        <h5 className="text-lg font-bold text-white mb-0 inline-flex items-center">
          Captchas Overview{" "}
          <img src={fireIcon} width={20} className="ml-2" alt="Fire Icon" />
        </h5>
        <a className="text-blue-500 no-underline" href="/statistics">
          View report
        </a>
      </div>

      <div className="grid grid-cols-1 gap-4">
        <div
          className="shadow rounded-lg p-4"
          style={{ backgroundColor: "rgb(24 36 80)" }}
        >
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-400">Today</h6>
              <span className="text-2xl font-bold text-white">
                {homeData.today_captcha > 0 ? homeData.today_captcha : "0"}
              </span>
            </div>
            <div>
              <img src={walletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </div>
        <div
          className="shadow rounded-lg p-4"
          style={{ backgroundColor: "rgb(24 36 80)" }}
        >
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-400">All Time</h6>
              <span className="text-2xl font-bold text-white">
                {homeData.overview.total_captchas}
              </span>
            </div>
            <div>
              <img src={walletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </div>
      </div>

      {/* transaction view for pending, calcelled, rejected, approved */}
      <div className="flex justify-between items-center mb-4 mt-4">
        <h5 className="text-lg font-bold text-white mb-0 inline-flex items-center">
          Transactions{" "}
          <img src={fireIcon} width={20} className="ml-2" alt="Fire Icon" />
        </h5>
        <a className="text-blue-500 no-underline" href="/statistics">
          View report
        </a>
      </div>

      <div className="grid grid-cols-1 gap-4">
        <div
          className="shadow rounded-lg p-4"
          style={{ backgroundColor: "rgb(24 36 80)" }}
        >
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-400">Paid</h6>
              <span className="text-2xl font-bold text-white">
                {homeData.transactions.paid}
              </span>
            </div>
            <div>
              <img src={walletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </div>
        <div
          className="shadow rounded-lg p-4"
          style={{ backgroundColor: "rgb(24 36 80)" }}
        >
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-400">Pending</h6>
              <span className="text-2xl font-bold text-white">
                {homeData.transactions.pending}
              </span>
            </div>
            <div>
              <img src={walletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </div>
        <div
          className="shadow rounded-lg p-4"
          style={{ backgroundColor: "rgb(24 36 80)" }}
        >
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-400">Cancelled</h6>
              <span className="text-2xl font-bold text-white">
                {homeData.transactions.cancelled}
              </span>
            </div>
            <div>
              <img src={walletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </div>
        <div
          className="shadow rounded-lg p-4"
          style={{ backgroundColor: "rgb(24 36 80)" }}
        >
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-400">All Time</h6>
              <span className="text-2xl font-bold text-white">
                {homeData.transactions.total}
              </span>
            </div>
            <div>
              <img src={walletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOverview;
