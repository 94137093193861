import React, { useEffect } from 'react';
import ProgressBar from './ProgressBar';
import '../css/LoadingScreen.css';
import noBgLogo from "../../images/img/dashboard/logo-white.png";

const LoadingScreen = () => {
  useEffect(() => {
    // Prevent scrolling when the loading screen is visible
    document.body.style.overflow = 'hidden';

    return () => {
      // Restore scrolling when loading screen is unmounted
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="loading-screen">
      <div className="progress-bar-container">
        <ProgressBar />
      </div>
      <div className="logo-container">
        <img src={noBgLogo} alt="Logo" className="logo" />
      </div>
    </div>
  );
};

export default LoadingScreen;
