import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import axios from "axios";

const Counts = () => {
    const [startCounting, setStartCounting] = useState(false);
    const [counters, setCounters] = useState({
        registeredUsers: 0,
        activeUsers: 0,
        completedCaptchas: 0,
        paidAmount: 0,
    });

    const { ref } = useInView({
        triggerOnce: true,
        onChange: (inView) => {
            if (inView) {
                setStartCounting(true);
            }
        },
    });

    useEffect(() => {
        if (startCounting) {

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/getCounts`;
            axios.post(apiUrl, { isCounts: true })
                .then((response) => {
                    const stats = response.data;
                    const increments = {
                        registeredUsers: Math.ceil(stats.registeredUsers / 200),
                        activeUsers: Math.ceil(stats.activeUsers / 200),
                        completedCaptchas: Math.ceil(stats.completedCaptchas / 200),
                        paidAmount: Math.ceil(stats.paidAmount / 200),
                    };

                    const interval = setInterval(() => {
                        setCounters((prev) => {
                            const newCounters = {
                                registeredUsers: Math.min(
                                    prev.registeredUsers + increments.registeredUsers,
                                    stats.registeredUsers
                                ),
                                activeUsers: Math.min(
                                    prev.activeUsers + increments.activeUsers,
                                    stats.activeUsers
                                ),
                                completedCaptchas: Math.min(
                                    prev.completedCaptchas + increments.completedCaptchas,
                                    stats.completedCaptchas
                                ),
                                paidAmount: Math.min(
                                    prev.paidAmount + increments.paidAmount,
                                    stats.paidAmount
                                ),
                            };

                            if (
                                newCounters.registeredUsers === stats.registeredUsers &&
                                newCounters.activeUsers === stats.activeUsers &&
                                newCounters.completedCaptchas === stats.completedCaptchas &&
                                newCounters.paidAmount === stats.paidAmount
                            ) {
                                clearInterval(interval);
                            }

                            return newCounters;
                        });
                    }, 30);

                    return () => clearInterval(interval);
                })
                .catch((error) => {
                    console.error('Error fetching data:', "Error Found");
                });
        }
    }, [startCounting]);

    const formatNumber = (number) => {
        return number.toLocaleString();
    };

    return (
        <section ref={ref} className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24">
            <div className="mx-auto">
                <div className="mx-auto max-w-2xl lg:max-w-none text-center space-y-4">
                    <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        Join Our Growing Community
                    </h2>
                    <p className="text-lg leading-8 text-gray-300">
                        Daily Captcha Work is trusted by thousands of users. See the impact
                        and success our platform has achieved!
                    </p>
                </div>

                <dl className="mt-16 grid grid-cols-1 gap-6 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                    <div className="flex flex-col bg-white/10 p-8 rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105">
                        <dd className="order-first text-5xl font-semibold tracking-tight text-white animate-pulse">
                            {formatNumber(counters.registeredUsers)}
                        </dd>
                        <dt className="text-sm font-semibold leading-6 text-gray-300 mt-2">
                            Registered Users
                        </dt>
                    </div>
                    <div className="flex flex-col bg-white/10 p-8 rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105">
                        <dd className="order-first text-5xl font-semibold tracking-tight text-white animate-pulse">
                            {formatNumber(counters.activeUsers)}
                        </dd>
                        <dt className="text-sm font-semibold leading-6 text-gray-300 mt-2">
                            Active Users
                        </dt>
                    </div>
                    <div className="flex flex-col bg-white/10 p-8 rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105">
                        <dd className="order-first text-5xl font-semibold tracking-tight text-white animate-pulse">
                            {formatNumber(counters.completedCaptchas)}
                        </dd>
                        <dt className="text-sm font-semibold leading-6 text-gray-300 mt-2">
                            Completed Captchas
                        </dt>
                    </div>
                    <div className="flex flex-col bg-white/10 p-8 rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105">
                        <dd className="order-first text-5xl font-semibold tracking-tight text-white animate-pulse">
                            ${formatNumber(counters.paidAmount)}
                        </dd>
                        <dt className="text-sm font-semibold leading-6 text-gray-300 mt-2">
                            Paid Amount
                        </dt>
                    </div>
                </dl>
            </div>
        </section>
    );
};

export default Counts;
