// src/components/CompleteHome.js

import React from 'react';
import Header from './Header';
import Register from './Register';
import SocialAd from './SocialAd';

const CompleteRegister = () => {
  return (
    <>
      <Header />
      <Register />
      <SocialAd/>
    </>
  );
}

export default CompleteRegister;
