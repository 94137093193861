import React from "react";
import AdSenseAd from "./AdSenseAd";
import BitmediaAd from "../dashboard/utils/BitmediaFloatAd";

const TaskRunner = () => {
  // Check if the task can run based on the current time
  const canRun = canRunTask();

  return (
    <div>{canRun ? <AdSenseAd /> : <BitmediaAd/>}</div>
  );
};

// Demo utility function
const canRunTask = () => {
  // Get the current time in the Indian time zone (IST)
  const currentTime = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const currentDateTime = new Date(currentTime);

  const currentHour = currentDateTime.getHours();
  const currentMinutes = currentDateTime.getMinutes();

  // Convert time to HH:MM format
  const currentFormattedTime = `${String(currentHour).padStart(
    2,
    "0"
  )}:${String(currentMinutes).padStart(2, "0")}`;

  // Define the time range
  const startTime = "08:00";
  const endTime = "23:59";

  // Return true if current time is within the range
  return currentFormattedTime >= startTime && currentFormattedTime <= endTime;
};

export default TaskRunner;
