import React, { useState, useEffect } from "react";
import fireIcon from "../images/img/dashboard/fire.svg";

const Reports = ({ reportsData }) => {
  const [filteredReports, setFilteredReports] = useState([]);
  const [timePeriod, setTimePeriod] = useState("all-time");

  useEffect(() => {
    if (Array.isArray(reportsData)) {
      setFilteredReports(reportsData);
    } else {
      console.error("reports is not an array", "Error Found");
    }
  }, [reportsData]);

  const filterReports = (period) => {
    setTimePeriod(period);
    const today = new Date();
    let filtered = [];

    if (!Array.isArray(reportsData)) {
      console.error("reports is not an array", "Error Found");
      return;
    }

    switch (period) {
      case "today":
        filtered = reportsData.filter((report) => {
          const reportDate = new Date(report.created);
          return reportDate.toDateString() === today.toDateString();
        });
        break;
      case "yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        filtered = reportsData.filter((report) => {
          const reportDate = new Date(report.created);
          return reportDate.toDateString() === yesterday.toDateString();
        });
        break;
      case "this-month":
        filtered = reportsData.filter((report) => {
          const reportDate = new Date(report.created);
          return (
            reportDate.getMonth() === today.getMonth() &&
            reportDate.getFullYear() === today.getFullYear()
          );
        });
        break;
      case "last-month":
        const lastMonth = new Date(today);
        lastMonth.setMonth(today.getMonth() - 1);
        filtered = reportsData.filter((report) => {
          const reportDate = new Date(report.created);
          return (
            reportDate.getMonth() === lastMonth.getMonth() &&
            reportDate.getFullYear() === lastMonth.getFullYear()
          );
        });
        break;
      default:
        filtered = reportsData;
        break;
    }

    setFilteredReports(filtered);
  };

  return (
    <div className="container">
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-lg font-bold text-white mb-0 inline-flex items-center">
          Statistics
          <img src={fireIcon} width={20} className="ml-2" alt="Fire Icon" />
        </h5>
      </div>
      <div className="flex overflow-x-auto hide-scrollbar space-x-4 mb-4">
        <button
          onClick={() => filterReports("all-time")}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            timePeriod === "all-time" ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          All Time
        </button>
        <button
          onClick={() => filterReports("today")}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            timePeriod === "today" ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          Today
        </button>
        <button
          onClick={() => filterReports("yesterday")}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            timePeriod === "yesterday" ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          Yesterday
        </button>
        <button
          onClick={() => filterReports("this-month")}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            timePeriod === "this-month" ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          This Month
        </button>
        <button
          onClick={() => filterReports("last-month")}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            timePeriod === "last-month" ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          Last Month
        </button>
      </div>
      <div className="statistics-container">
        <ul className="space-y-4 p-0">
          {filteredReports.length > 0 ? (
            filteredReports.map((report, index) => (
              <li key={index} className="report-item">
                <div className="flex justify-between items-center mb-2">
                  <h6 className="text-gray-400">Sl No</h6>
                  <h6 className="text-white">{index + 1}</h6>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <h6 className="text-gray-400">ID</h6>
                  <h6 className="text-white">{report.id}</h6>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <h6 className="text-gray-400">Captchas</h6>
                  <h6 className="text-white">{report.clicks}</h6>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <h6 className="text-gray-400">Earnings</h6>
                  <h6 className="text-white">{report.earning}</h6>
                </div>
                <div className="flex justify-between items-center">
                  <h6 className="text-gray-400">Date</h6>
                  <h6 className="text-white">{report.created}</h6>
                </div>
                <hr />
              </li>
            ))
          ) : (
            <div
              className="px-4 py-3 rounded relative"
              role="alert"
              style={{ backgroundColor: "rgb(24 36 80)" }}
            >
              <span className="block sm:inline">
                No reports found for the selected period.
              </span>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Reports;
