import React, { useEffect, useState } from "react";
import axios from "axios";

import DashboardHeader from "./DashboardHeader";
import Welcome from "./Welcome";
import Footer from "./Footer";
import WithdrawPage from "./WithdrawPage";
import WithdrawTransactions from "./WithdrawTransactions";
import LoadingScreen from "./utils/LoadingScreen";

const Payout = ({ userDetails }) => {
  const [payoutData, setPayoutData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (userDetails) {
      const fetchData = async () => {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/getTransactions`;

          const response = await axios.post(
            apiUrl,
            { userId: userDetails.user_data.id },
            { withCredentials: true }
          );
          setPayoutData(response.data);
        } catch (error) {
          setError("An error occurred while fetching data.");
        }
      };
      fetchData();
    }
  }, [userDetails]);

  if (!payoutData) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen text-white"
      style={{ backgroundColor: "rgb(14 28 64)", paddingBottom: "70px" }}
    >
      <main className="main-wrapper">
        <div className="main-container">
          <DashboardHeader />
          <Welcome userDetails={userDetails} />
          <WithdrawPage
            paymentDetails={payoutData.payment_details}
            userDetails={userDetails}
          />
          <WithdrawTransactions transactions={payoutData} />
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default Payout;
