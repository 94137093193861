import React from "react";

const Footer = () => {
  return (
    <footer className=" text-white py-2 mt-4">
      <div className="container mx-auto flex flex-col items-center">
        <h1 className="text-lg font-bold">DCW</h1>
        <p className="text-sm">Version 1.0.0</p>
        <p className="text-xs mt-1">&copy; 2024 DCW. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
