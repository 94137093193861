import React, { useEffect, useState } from "react";
import axios from "axios";

import DashboardHeader from "./DashboardHeader";
import Welcome from "./Welcome";
import Footer from "./Footer";
import Reports from "./Reports";
import LoadingScreen from "./utils/LoadingScreen";

const Statistics = ({ userDetails }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (userDetails) {
      const fetchData = async () => {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/getStatistics`;

          const response = await axios.post(
            apiUrl,
            { userId: userDetails.user_data.id },
            { withCredentials: true }
          );
          setData(response.data);
        } catch (error) {
          setError("An error occurred while fetching data.");
        }
      };

      fetchData();
    }
  }, [userDetails]);

  if (!data) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen text-white"
      style={{ backgroundColor: "rgb(14 28 64)", paddingBottom: "70px" }}
    >
      <main className="main-wrapper">
        <div className="main-container">
          <DashboardHeader />
          <Welcome userDetails={userDetails} />
          <Reports reportsData={data.data} />
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default Statistics;
