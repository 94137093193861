import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./css/Captcha.css";
import BitmediaAd from "./utils/BitmediaFloatAd";
import Article from "./utils/Article";

const CaptchaPage = ({ todayCaptchas, userDetails }) => {
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");
  const [article, setArticle] = useState(false);
  const [isCaptchaVerified] = useState(
    JSON.parse(sessionStorage.getItem("isCaptchaVerified")) || false
  );
  const [currentStep] = useState(
    parseInt(sessionStorage.getItem("currentStep")) || 1
  );
  const [showModal, setShowModal] = useState(false);
  const [adClicked, setAdClicked] = useState(false);
  const [timer, setTimer] = useState(5);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const lastAlertedCaptchaCount = useRef(null);
  const [uniqueAdClicked, setUniqueAdClicked] = useState(false);
  const [scrollTimer, setScrollTimer] = useState(2); // Set initial timer value to 2 seconds
  const [isScrollDisabled, setIsScrollDisabled] = useState(true); // State to manage scroll
  const [finishCaptchaState, setFinishCaptchaState] = useState(false);
  const [isFinishCaptchaClicked, setIsFinishCaptchaClicked] = useState(false);
  const [finishError, setFinishError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAdVisible, setIsAdVisible] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const startTimeRef = useRef(null);

  function generateCaptcha() {
    return Math.floor(1000 + Math.random() * 9000).toString();
  }

  useEffect(() => {
    const captchaCount = parseInt(todayCaptchas.today_captchas, 10);
    if (
      captchaCount > 0 &&
      captchaCount % 20 === 0 &&
      captchaCount !== lastAlertedCaptchaCount.current
    ) {
      setShowCaptchaModal(true);
      lastAlertedCaptchaCount.current = captchaCount;
    }
  }, [todayCaptchas.today_captchas]);

  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = "hidden";

    // Countdown timer
    const interval = setInterval(() => {
      setScrollTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setIsScrollDisabled(false); // Re-enable scroll
          document.body.style.overflow = "auto"; // Restore scroll
          return 0; // Reset timer to 0
        }
        return prev - 1; // Decrement timer
      });
    }, 1000); // Update every second

    return () => {
      clearInterval(interval); // Cleanup interval on component unmount
      document.body.style.overflow = "auto"; // Ensure scrolling is re-enabled
    };
  }, []);

  useEffect(() => {
    if (isCaptchaVerified) {
      setArticle(true);
    }
  }, [isCaptchaVerified]);

  useEffect(() => {
    let interval;
    if (adClicked && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      handleModalComplete();
    }
    return () => clearInterval(interval);
  }, [adClicked, timer]);

  const handleChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = () => {
    if (userInput === captcha) {
      setShowModal(true);
      startTimeRef.current = Date.now();
      sessionStorage.setItem("startTime", startTimeRef.current);
    } else {
      alert("Captcha did not match. Please try again.");
      setCaptcha(generateCaptcha());
      setUserInput("");
    }
  };

  const handleAdClick = () => {
    setAdClicked(true);
  };

  const handleModalComplete = () => {
    setShowModal(false);
    setAdClicked(false);
    setTimer(5);
    sessionStorage.setItem("isCaptchaVerified", true);
    setUserInput("");
    window.location.reload(); // Refresh to update state
  };

  const handleNextStep = () => {
    if (currentStep < 5) {
      sessionStorage.setItem("currentStep", currentStep + 1);

      const totalSteps = parseInt(sessionStorage.getItem("totalSteps")) || 0;
      sessionStorage.setItem("totalSteps", totalSteps + 1);

      window.location.reload(); // Refresh to update step
    }
    if (currentStep === 5) {
      sessionStorage.setItem("currentStep", 6);

      const totalSteps = parseInt(sessionStorage.getItem("totalSteps")) || 0;
      sessionStorage.setItem("totalSteps", totalSteps + 1);

      window.location.reload(); // Refresh to update step
    }
  };

  const handleFinishCaptcha = () => {
    if (isFinishCaptchaClicked) {
      return; // Prevent the function from running if already clicked
    }

    // Set the flag to indicate that the button has been clicked
    setIsFinishCaptchaClicked(true);
    setFinishCaptchaState(true);
    setLoading(true); // Start loading

    const endTime = Date.now();
    const startTime = parseInt(sessionStorage.getItem("startTime"));
    const totalTimeTaken = Math.round((endTime - startTime) / 1000);

    const totalSteps = parseInt(sessionStorage.getItem("totalSteps")) || 0;
    const totalUniqueScrolled =
      parseInt(sessionStorage.getItem("totalUniqueScrolled")) || 0;
    const totalScrolled =
      parseInt(sessionStorage.getItem("totalScrolled")) || 0;

    // Call the API to update captcha completion details
    updateCompleteCaptcha(
      totalTimeTaken,
      totalSteps,
      totalUniqueScrolled,
      totalScrolled
    );

    // Clear session storage after completion
    sessionStorage.removeItem("isCaptchaVerified");
    sessionStorage.removeItem("currentStep");
    sessionStorage.removeItem("startTime");
    sessionStorage.removeItem("totalSteps");
    sessionStorage.removeItem("totalUniqueScrolled");
    sessionStorage.removeItem("totalScrolled");

    // Generate a new captcha
    setCaptcha(generateCaptcha());
  };

  // update captcha details in database
  const updateCompleteCaptcha = async (
    totalTimeTaken,
    totalSteps,
    totalUniqueScrolled,
    totalScrolled
  ) => {
    if (userDetails) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/submitCaptcha`;

        const response = await axios.post(
          apiUrl,
          {
            userId: userDetails.user_data.id,
            totalTimeTaken: totalTimeTaken,
            totalSteps: totalSteps,
            totalUniqueScrolled: totalUniqueScrolled,
            totalScrolled: totalScrolled,
          },
          { withCredentials: true }
        );
        if (response.data.status === "success") {
          window.location.reload();
        } else {
          setFinishError(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", "Error Found");
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const updateUniqueAdClick = async () => {
    if (userDetails) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/uniqueAd`;

        await axios.post(
          apiUrl,
          { userId: userDetails.user_data.id },
          { withCredentials: true }
        );
      } catch (error) {
        console.error("Error fetching data:", "Error Found");
      }
    }
  };

  const handleUniqueAdClick = () => {
    if (!uniqueAdClicked) {
      setShowCaptchaModal(false);
      setUniqueAdClicked(true);
      updateUniqueAdClick();
    }
  };

  // track scroll
  useEffect(() => {
    const handleUniqueScroll = () => {
      // Calculate the bottom of the page
      const scrollPosition = window.innerHeight + window.scrollY;
      const pageHeight = document.documentElement.offsetHeight;

      // Check if user has reached the bottom
      if (scrollPosition >= pageHeight) {
        const currentStep =
          parseInt(sessionStorage.getItem("currentStep")) || 0;
        const totalScrolled =
          parseInt(sessionStorage.getItem("totalScrolled")) || 0;
        sessionStorage.setItem("totalUniqueScrolled", currentStep);
        sessionStorage.setItem("totalScrolled", totalScrolled + 1);
        window.removeEventListener("scroll", handleUniqueScroll);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleUniqueScroll);

    // Clean up by removing the event listener
    return () => {
      window.removeEventListener("scroll", handleUniqueScroll);
    };
  }, []);

  const handleCloseAdBtn = () => {
    setIsFadingOut(true); // Start fade-out animation
    setTimeout(() => {
      setIsAdVisible(false); // Hide the ad after animation completes
    }, 500); // Duration of the animation in milliseconds
  };

  return (
    <div
      className={`flex items-center justify-center ${
        article ? "min-h-screen" : "pt-16"
      }`}
    >
      {finishError && <div>{finishError}</div>}

      {isAdVisible && (
        <div className={`bitmediaFloatAd ${isFadingOut ? "fade-out" : ""}`}>
          <button
            onClick={handleCloseAdBtn}
            className="close-btn-dismiss bitMediaFloatCloseButton no-underline"
            type="button"
          >
            <span className="text-lg text-blue-400">x</span>
          </button>
          <BitmediaAd />
        </div>
      )}

      {!isCaptchaVerified && (
        <div className="max-w-md w-full text-center container">
          <div className="mb-2">
            <BitmediaAd />
          </div>
          <div className="flex items-center justify-center mb-4">
            <span
              className="text-lg lato-bold text-white w-full py-2 rounded-lg"
              style={{ backgroundColor: "rgb(0 30 141)" }}
            >
              Captcha Code: {captcha}
            </span>
          </div>
          <input
            type="number"
            value={userInput}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded-lg support-border bg-transparent text-white"
            placeholder="Enter captcha here"
          />
          <button
            onClick={handleSubmit}
            className="w-full bg-blue-700 mt-3 text-white py-2 rounded-md hover:bg-purple-800 transition duration-300"
            disabled={showModal}
          >
            Submit
          </button>

          <div className="mb-2 mt-3">
            <BitmediaAd />
          </div>
        </div>
      )}

      {isCaptchaVerified && article && (
        <div className="rounded-lg shadow-lg pt-20 pl-3 pr-3 pb-3 max-w-3xl w-full overflow-auto">
          {isScrollDisabled && (
            <p className="text-red-600 mt-2 mb-2 text-center">
              Wait for {scrollTimer} seconds...
            </p>
          )}

          <Article />

          {currentStep <= 5 && (
            <button
              onClick={handleNextStep}
              className="w-full bg-blue-700 text-white py-2 rounded-md hover:bg-blue-800 transition duration-300"
            >
              {`Step ${currentStep}`}
            </button>
          )}
          {currentStep === 6 && (
            <button
              disabled={finishCaptchaState}
              onClick={handleFinishCaptcha}
              className="w-full bg-green-700 mt-3 text-white py-2 rounded-md hover:bg-green-800 transition duration-300"
            >
              {loading && (
                <span
                  className="spinner-grow spinner-grow-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Finish Captcha
            </button>
          )}
        </div>
      )}

      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center modal-enter">
          <div className="bg-blue-950 w-full max-w-3xl p-4 shadow-lg">
            <h2 className="text-xl font-bold mb-4 text-center">
              {!adClicked
                ? "Click on below ad"
                : `Wait on ad page ${timer} seconds`}
            </h2>
            {!adClicked ? (
              <a
                href="https://slushhelmetmirth.com/drw5h2t9?key=ca116f9836942079238fccc3b25961ad"
                target="_blank"
                rel="noreferrer"
                className="w-72 h-64 bg-blue-200 flex items-center justify-center cursor-pointer m-auto no-underline"
                onClick={handleAdClick}
              >
                <div className="ad-container-text text-center">
                <p className="text-2xl font-bold text-gray-700">
                  Ad Content
                </p>
                <p className="text-xl text-orange-500 lato-bold">Click Me</p>
                </div>
              </a>
            ) : (
              <div className="w-72 h-64 bg-blue-200 flex items-center justify-center m-auto">
                <span className="text-xl font-bold text-gray-700">
                  Verifying your click...
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      {showCaptchaModal && !article && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center">
          <div className="bg-blue-950 w-full max-w-3xl p-4 shadow-lg">
            <div className="bg-red-900 h-10 text-center text-xl font-bold flex items-center justify-center text-white mb-3">
              Compulsory Ad
            </div>
            <div
              className="w-72 h-64 bg-blue-200 flex items-center justify-center cursor-pointer m-auto"
              onClick={handleUniqueAdClick}
            >
              <span className="text-xl font-bold text-gray-700">
                Ad Content
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaptchaPage;
