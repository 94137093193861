import React, { useState } from "react";
import "./css/Header.module.css";
import noBgLogo from "../images/img/dashboard/logo-white.png";
import sidebarIcon from "../images/img/dashboard/sidebar.svg";

import homeIcon from "../images/img/dashboard/bottom_home.svg";
import profileIcon from "../images/img/dashboard/bottom_profile.svg";
import payoutIcon from "../images/img/dashboard/wallet.svg";
import statisticsIcon from "../images/img/dashboard/bottom_statistics.svg";
import shareIcon from "../images/img/dashboard/share.svg";
import settingsIcon from "../images/img/dashboard/settings.svg";
import lockIcon from "../images/img/dashboard/lock.svg";
import logoutIcon from "../images/img/dashboard/logout.svg";
import supportIcon from "../images/img/dashboard/bottom_support.svg";

// share app icons
import whatsappIcon from "../images/img/dashboard/whatsapp.svg";
import instagramIcon from "../images/img/dashboard/instagram.svg";
import facebookIcon from "../images/img/dashboard/facebook.svg";
import twitterIcon from "../images/img/dashboard/twitter.svg";

import avatarIcon from "../images/img/dashboard/profile_avatar.gif"; // Replace with the avatar image path
import { Link } from "react-router-dom";

const ShareApp = ({ onClose }) => {
  const [isExiting, setIsExiting] = useState(false);

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
    }, 300); // Adjust the timeout to match your exit transition duration
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 ${
        isExiting ? "modal-exit" : "modal-enter"
      }`}
    >
      <div
        className="w-11/12 max-w-2xl p-4 rounded-lg shadow-lg relative"
        style={{ backgroundColor: "rgb(24, 36, 80)" }}
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-white hover:text-blue-900 text-2xl"
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-6 text-center text-white">
          Share App
        </h2>

        <div className="space-y-4 max-h-80 overflow-y-auto hide-scrollbar">
          <div className="space-y-4 max-h-80 overflow-y-auto hide-scrollbar">
            <div className="flex justify-center space-x-4">
              <button
                className="w-32 flex items-center justify-center hover:bg-blue-700 text-white font-bold py-1.5 px-3 text-sm rounded-md"
                style={{ backgroundColor: "rgb(28 52 141)" }}
              >
                <img
                  src={facebookIcon}
                  alt="Facebook Logo"
                  width={20}
                  className="mr-2"
                />
                Facebook
              </button>
              <button
                className="w-32 flex items-center justify-center hover:bg-blue-600 text-white font-bold py-1.5 px-3 text-sm rounded-md"
                style={{ backgroundColor: "rgb(28 52 141)" }}
              >
                <img
                  src={twitterIcon}
                  alt="Twitter Logo"
                  width={20}
                  className="mr-2"
                />
                Twitter
              </button>
            </div>

            <div className="flex justify-center space-x-4 mt-4">
              <button
                className="w-32 flex items-center justify-center hover:bg-blue-700 text-white font-bold py-1.5 px-3 text-sm rounded-md"
                style={{ backgroundColor: "rgb(28 52 141)" }}
              >
                <img
                  src={whatsappIcon}
                  alt="WhatsApp Logo"
                  width={18}
                  className="mr-2"
                />
                WhatsApp
              </button>
              <button
                className="w-32 flex items-center justify-center hover:bg-blue-600 text-white font-bold py-1.5 px-3 text-sm rounded-md"
                style={{ backgroundColor: "rgb(28 52 141)" }}
              >
                <img
                  src={instagramIcon}
                  alt="Instagram Logo"
                  width={18}
                  className="mr-2"
                />
                Instagram
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center space-x-2">
            <input
              type="text"
              readOnly
              className="flex-grow p-1.5 rounded-md bg-gray-700 text-white text-sm"
              value="https://yourappwebsite.com"
            />
            <button className="bg-gray-700 hover:bg-gray-800 text-white font-bold py-1.5 px-3 rounded-md text-sm">
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardHeader = () => {
  const [show, setShow] = useState(false);
  const [isShareAppOpen, setIsShareAppOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showSideHeader, setSideHeaderShow] = useState(false);

  const openShareApp = (e) => {
    e.preventDefault();
    setIsShareAppOpen(true);
  };

  const closeShareApp = () => {
    setIsShareAppOpen(false);
  };

  return (
    <header
      className="flex justify-between items-center p-3 text-white mb-2 fixed top-0 w-full shadow-2xl z-50" // Ensure header is on top
      style={{ backgroundColor: "rgb(14, 28, 64)" }}
    >
      <div onClick={handleShow} className="cursor-pointer">
        <img src={sidebarIcon} alt="Sidebar Icon" className="w-6 h-6" />
      </div>

      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-40 transition-opacity duration-300 ${
          show ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={handleClose}
      >
        <div
          style={{ backgroundColor: "rgb(14, 28, 64)" }}
          className={`fixed top-0 left-0 h-full w-64 p-6 transform transition-transform duration-300 ease-in-out z-50 ${
            show ? "translate-x-0" : "-translate-x-full"
          } overflow-y-auto`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center mb-4">
            <img src={noBgLogo} alt="Logo" width={75} />
            <h3 className="primary-text text-xl ml-2 mt-1 font-extrabold bg-gradient-to-r from-teal-500 to-blue-500 bg-clip-text text-transparent">
              Workplace
            </h3>
          </div>
          <nav className="flex flex-col space-y-2">
            <Link
              to="/app/home"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
              onClick={handleClose} // Close sidebar on click
            >
              <img src={homeIcon} width={20} className="mr-2" alt="Home Icon" />{" "}
              Home
            </Link>
            <Link
              to="/app/payout"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
              onClick={handleClose}
            >
              <img
                src={payoutIcon}
                width={20}
                className="mr-2"
                alt="Payout Icon"
              />{" "}
              Payout
            </Link>
            <Link
              to="/app/profile"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
              onClick={handleClose}
            >
              <img
                src={profileIcon}
                width={20}
                className="mr-2"
                alt="Profile"
              />{" "}
              Profile
            </Link>
            <Link
              to="/app/statistics"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
              onClick={handleClose}
            >
              <img
                src={statisticsIcon}
                width={20}
                className="mr-2"
                alt="Stats"
              />
              Statistics
            </Link>
            <Link
              to="/app/support"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
              onClick={handleClose}
            >
              <img
                src={supportIcon}
                width={20}
                className="mr-2"
                alt="Support Icon"
              />{" "}
              Support
            </Link>
            <button
              type="button"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
              onClick={openShareApp}
            >
              <img
                src={shareIcon}
                width={20}
                className="mr-2"
                alt="Share Icon"
              />{" "}
              Share
            </button>
            <Link
              to="/app/settings"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
              onClick={handleClose}
            >
              <img
                src={settingsIcon}
                width={20}
                className="mr-2"
                alt="Settings Icon"
              />{" "}
              Settings
            </Link>
          </nav>
          <div className="text-gray-500 py-2 mt-4">
            <div className="container mx-auto flex flex-col items-center">
              <h1 className="text-lg font-bold">Daily Captcha Work</h1>
              <p className="text-sm">Version 1.0.0</p>
              <p className="text-xs mt-1">
                &copy; 2024 DCW. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative z-50">
        {" "}
        {/* Ensure this is on top */}
        <div
          className="cursor-pointer flex items-center"
          onClick={() => setSideHeaderShow(!showSideHeader)}
        >
          <img src={avatarIcon} alt="Avatar" className="rounded-full w-8 h-8" />
        </div>
        {showSideHeader && (
          <div
            className="absolute right-0 mt-2 w-60 rounded-lg shadow-lg p-3 z-50" // Ensure dropdown is on top
            style={{ backgroundColor: "rgb(24, 36, 80)" }}
          >
            <Link
              to="/contact"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
            >
              <img
                src={profileIcon}
                width={20}
                className="mr-2"
                alt="Profile Icon"
              />{" "}
              Profile
            </Link>
            <Link
              to="/contact"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
            >
              <img src={lockIcon} width={20} className="mr-2" alt="Lock" />{" "}
              Change Password
            </Link>
            <hr />
            <Link
              to="/contact"
              className="flex items-center text-white hover:bg-blue-700 p-2 rounded no-underline"
            >
              <img src={logoutIcon} width={20} className="mr-2" alt="Logout" />{" "}
              Logout
            </Link>
          </div>
        )}
      </div>

      {isShareAppOpen && <ShareApp onClose={closeShareApp} />}
    </header>
  );
};

export default DashboardHeader;
