import React from "react";
// Import images
import FAQSection from "./FAQSection";
import ContactUs from "./ContactUs";
import Counts from "./Counts";
import Footer from "./Footer";
import PaymentProofs from "./PaymentProofs";
import OurFeatures from "./OurFeatures";
import HowToWork from "./HowToWork";
import Hero from "./Hero";
import WaysOfEarning from "./WaysOfEarning";
import PaymentMethods from "./PaymentMethods";
import Testimonals from "./Testimonals";
import SocialAd from "./SocialAd";

const Home = () => {
  return (
    <>
      <div className="hero-home-wrapper relative">
        {/* SVG Bubbles */}
        <div className="fixed inset-0 z-0">
          {/* Big Bubble */}
          <svg
            width="824"
            height="824"
            viewBox="0 0 824 824"
            fill="none"
            className="style_circle style_bigCircle"
          >
            <g filter="url(#filter0_f_2_8584)">
              <circle
                cx="412"
                cy="412"
                r="325"
                fill="#373350"
                fillOpacity="0.58"
              ></circle>
            </g>
            <defs>
              <filter
                id="filter0_f_2_8584"
                x="0.0149841"
                y="0.0149841"
                width="823.97"
                height="823.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                ></feBlend>
                <feGaussianBlur
                  stdDeviation="43.5"
                  result="effect1_foregroundBlur_2_8584"
                ></feGaussianBlur>
              </filter>
            </defs>
          </svg>

          {/* Medium Bubble 1 */}
          <svg
            width="389"
            height="389"
            viewBox="0 0 389 389"
            fill="none"
            className="style_circle style_mediumCircle_1"
          >
            <g opacity="0.4" filter="url(#filter0_f_2_8585)">
              <circle
                cx="194.5"
                cy="194.5"
                r="131.5"
                fill="url(#paint0_radial_2_8585)"
              ></circle>
            </g>
            <defs>
              <filter
                id="filter0_f_2_8585"
                x="0.479519"
                y="0.479519"
                width="388.041"
                height="388.041"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                ></feBlend>
                <feGaussianBlur
                  stdDeviation="31.26"
                  result="effect1_foregroundBlur_2_8585"
                ></feGaussianBlur>
              </filter>
              <radialGradient
                id="paint0_radial_2_8585"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(240.417 240.048) rotate(90) scale(255.072)"
              >
                <stop></stop>
                <stop offset="1" stopColor="#F80B49" stopOpacity="0.5"></stop>
              </radialGradient>
            </defs>
          </svg>

          {/* Medium Bubble 2 */}
          <svg
            width="350"
            height="350"
            viewBox="0 0 350 350"
            fill="none"
            className="style_circle style_mediumCircle_2"
          >
            <g filter="url(#filter0_f_2_29746)">
              <circle
                cx="175"
                cy="175"
                r="112"
                fill="url(#paint0_radial_2_29746)"
              ></circle>
            </g>
            <defs>
              <filter
                id="filter0_f_2_29746"
                x="0.479519"
                y="0.479519"
                width="349.041"
                height="349.041"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                ></feBlend>
                <feGaussianBlur
                  stdDeviation="31.26"
                  result="effect1_foregroundBlur_2_29746"
                ></feGaussianBlur>
              </filter>
              <radialGradient
                id="paint0_radial_2_29746"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(214.108 213.793) rotate(90) scale(217.247)"
              >
                <stop></stop>
                <stop offset="1" stopColor="#F80B49" stopOpacity="0.5"></stop>
              </radialGradient>
            </defs>
          </svg>

          {/* Small Bubble */}
          <svg
            width="135"
            height="135"
            viewBox="0 0 135 135"
            fill="none"
            className="style_circle style_smallCircle"
          >
            <g filter="url(#filter0_f_2_29744)">
              <circle
                cx="67.5"
                cy="67.5"
                r="47.5"
                fill="url(#paint0_radial_2_29744)"
              ></circle>
            </g>
            <defs>
              <filter
                id="filter0_f_2_29744"
                x="0.972027"
                y="0.972027"
                width="133.056"
                height="133.056"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                ></feBlend>
                <feGaussianBlur
                  stdDeviation="9.51"
                  result="effect1_foregroundBlur_2_29744"
                ></feGaussianBlur>
              </filter>
              <radialGradient
                id="paint0_radial_2_29744"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(84.0861 83.9525) rotate(90) scale(86.9506)"
              >
                <stop></stop>
                <stop offset="1" stopColor="#27398A"></stop>
              </radialGradient>
            </defs>
          </svg>
        </div>

        {/* Scrollable Content */}
        <div className="hero-home-wrapper relative">
          {/* SVG Bubbles */}
          <div className="fixed inset-0 z-0">
            {/* Big Bubble */}
            <svg
              width="824"
              height="824"
              viewBox="0 0 824 824"
              fill="none"
              className="style_circle style_bigCircle"
            >
              <g filter="url(#filter0_f_2_8584)">
                <circle
                  cx="412"
                  cy="412"
                  r="325"
                  fill="#373350"
                  fillOpacity="0.58"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_2_8584"
                  x="0.0149841"
                  y="0.0149841"
                  width="823.97"
                  height="823.97"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="43.5"
                    result="effect1_foregroundBlur_2_8584"
                  ></feGaussianBlur>
                </filter>
              </defs>
            </svg>

            {/* Medium Bubble 1 */}
            <svg
              width="389"
              height="389"
              viewBox="0 0 389 389"
              fill="none"
              className="style_circle style_mediumCircle_1"
            >
              <g opacity="0.4" filter="url(#filter0_f_2_8585)">
                <circle
                  cx="194.5"
                  cy="194.5"
                  r="131.5"
                  fill="url(#paint0_radial_2_8585)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_2_8585"
                  x="0.479519"
                  y="0.479519"
                  width="388.041"
                  height="388.041"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="31.26"
                    result="effect1_foregroundBlur_2_8585"
                  ></feGaussianBlur>
                </filter>
                <radialGradient
                  id="paint0_radial_2_8585"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(240.417 240.048) rotate(90) scale(255.072)"
                >
                  <stop></stop>
                  <stop offset="1" stopColor="#F80B49" stopOpacity="0.5"></stop>
                </radialGradient>
              </defs>
            </svg>

            {/* Medium Bubble 2 */}
            <svg
              width="350"
              height="350"
              viewBox="0 0 350 350"
              fill="none"
              className="style_circle style_mediumCircle_2"
            >
              <g filter="url(#filter0_f_2_29746)">
                <circle
                  cx="175"
                  cy="175"
                  r="112"
                  fill="url(#paint0_radial_2_29746)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_2_29746"
                  x="0.479519"
                  y="0.479519"
                  width="349.041"
                  height="349.041"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="31.26"
                    result="effect1_foregroundBlur_2_29746"
                  ></feGaussianBlur>
                </filter>
                <radialGradient
                  id="paint0_radial_2_29746"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(214.108 213.793) rotate(90) scale(217.247)"
                >
                  <stop></stop>
                  <stop offset="1" stopColor="#F80B49" stopOpacity="0.5"></stop>
                </radialGradient>
              </defs>
            </svg>

            {/* Small Bubble */}
            <svg
              width="135"
              height="135"
              viewBox="0 0 135 135"
              fill="none"
              className="style_circle style_smallCircle"
            >
              <g filter="url(#filter0_f_2_29744)">
                <circle
                  cx="67.5"
                  cy="67.5"
                  r="47.5"
                  fill="url(#paint0_radial_2_29744)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_2_29744"
                  x="0.972027"
                  y="0.972027"
                  width="133.056"
                  height="133.056"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="9.51"
                    result="effect1_foregroundBlur_2_29744"
                  ></feGaussianBlur>
                </filter>
                <radialGradient
                  id="paint0_radial_2_29744"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(84.0861 83.9525) rotate(90) scale(86.9506)"
                >
                  <stop></stop>
                  <stop offset="1" stopColor="#27398A"></stop>
                </radialGradient>
              </defs>
            </svg>
          </div>

          {/* Scrollable Content */}
          <div
            className="relative z-10 overflow-auto"
            style={{ height: "100vh" }}
          >
            <div className="max-w-screen-2xl mx-auto px-2">
              {/* Hero Section */}
              <Hero />

              <OurFeatures/>

              <HowToWork />

              <WaysOfEarning/>

              <PaymentMethods/>

              <Counts/>

              <PaymentProofs/>

              <Testimonals/>

              <FAQSection/>

              <ContactUs/>

              <Footer/>
              
              <SocialAd/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
