import React from "react";

// Payment icons
import bankTIcon from "../images/img/payments/banktransfer.svg";
import paytmIcon from "../images/img/payments/paytm.svg";
import upiIcon from "../images/img/payments/upi.svg";
import bitcoinIcon from "../images/img/payments/bitcoin.svg";
import usdtIcon from "../images/img/payments/usdt.svg";
import paypalIcon from "../images/img/payments/paypal.svg";

const PaymentMethods = () => {
  const paymentMethods = [
    { src: bankTIcon, label: "Bank Transfer", size: "80px", margin: "mt-3" },
    { src: upiIcon, label: "UPI", size: "170px", margin: "mt-3" },
    { src: paytmIcon, label: "PayTm", size: "120px", margin: "mt-10" },
    { src: paypalIcon, label: "PayPal", size: "180px", margin: "mt-6" },
    { src: bitcoinIcon, label: "Bitcoin", size: "180px", margin: "mt-11" },
    { src: usdtIcon, label: "USDT", size: "80px", margin: "mt-3" },
  ];

  return (
    <section className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24">
      <div className="mb-12 sm:mb-20 mx-auto max-w-2xl lg:max-w-none text-center space-y-4">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight text-white">
          Payouts via 5+ Payment Methods
        </h2>
        <p className="text-base sm:text-lg leading-8 text-gray-300">
          Get your earnings through multiple payment options. Choose the method
          that works best for you.
        </p>
      </div>

      <div className="flex flex-wrap justify-center items-center">
        {paymentMethods.map(({ src, label, size, margin }) => (
          <div
            key={label}
            className="icon-container mx-4 my-4 p-6 text-center transition-transform duration-300 transform hover:scale-110"
          >
            <img
              src={src}
              alt={label}
              className={`mx-auto mb-2 ${margin}`}
              style={{ width: size, filter: "brightness(1) contrast(1.2)" }}
            />
            <h3 className="text-lg font-semibold text-indigo-600">{label}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PaymentMethods;
