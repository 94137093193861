import { useEffect } from 'react';

const useAdLoadScript = (src) => {
  useEffect(() => {
    // Check if the script is already added
    if (document.querySelector(`script[src="${src}"]`)) {
      return;
    }

    // Create a new script element
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.crossOrigin = 'anonymous';
    script.defer = true;

    // Handle script loading error
    script.onerror = () => {
      console.error('Failed to load the ad script:', src);
    };

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [src]);
};

export default useAdLoadScript;
