import React, { useState, useEffect } from "react";
import fireIcon from "../images/img/dashboard/fire.svg";
import "./css/WithdrawTransactions.css"; // Import custom CSS for hiding scrollbars
import TransactionModal from "./utils/TransactionModal";

const WithdrawTransactions = ({ transactions }) => {
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [statusFilter, setStatusFilter] = useState("all");
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  useEffect(() => {
    if (Array.isArray(transactions.data)) {
      setFilteredTransactions(transactions.data);
    } else {
      console.error("transactions is not an array", "Error Found");
    }
  }, [transactions]);

  const filterTransactions = (status) => {
    setStatusFilter(status);
    let filtered = [];

    if (!Array.isArray(transactions.data)) {
      console.error("transactions is not an array", "Error Found");
      return;
    }

    if (status === "all") {
      filtered = transactions.data;
    } else {
      filtered = transactions.data.filter(
        (transaction) => transaction.status === status
      );
    }

    setFilteredTransactions(filtered);
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Cancelled";
      case 3:
        return "Approved";
      default:
        return "Unknown";
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "text-yellow-500";
      case 2:
        return "text-red-500";
      case 3:
        return "text-green-500";
      default:
        return "text-gray-500";
    }
  };

  return (
    <div className="container mx-auto mt-4">
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-lg font-bold text-white mb-0 inline-flex items-center">
          Transactions
          <img src={fireIcon} width={20} className="ml-2" alt="Fire Icon" />
        </h5>
      </div>
      <div className="flex overflow-x-auto hide-scrollbar space-x-4 mb-4">
        <button
          onClick={() => filterTransactions("all")}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            statusFilter === "all" ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          All
        </button>
        <button
          onClick={() => filterTransactions(3)}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            statusFilter === 3 ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          Approved
        </button>
        <button
          onClick={() => filterTransactions(1)}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            statusFilter === 1 ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          Pending
        </button>
        <button
          onClick={() => filterTransactions(2)}
          className={`flex-shrink-0 transaction-btn-border text-white hover:bg-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.2)] font-bold py-2 px-4 rounded-[8px] transition duration-300 ${
            statusFilter === 2 ? "bg-[rgba(255,255,255,0.2)]" : ""
          }`}
        >
          Cancelled
        </button>
      </div>
      <div className="transaction-history">
        {filteredTransactions.length > 0 ? (
          filteredTransactions.map((transaction, index) => (
            <div
              key={transaction.id}
              className="transaction-single-item cursor-pointer"
              onClick={() => setSelectedTransaction(transaction)}
            >
              <div className="flex items-center p-1">
                <div className="w-8 text-left">
                  <h3 className="text-xl text-white">{index + 1}</h3>
                </div>
                <div className="flex-1 ml-4">
                  <div className="flex justify-between items-center">
                    <h6 className="text-lg text-white">
                      #{transaction.id} / {transaction.method_name}
                    </h6>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <h6 className="text-lg text-white mr-3">
                        ${parseFloat(transaction.amount).toFixed(4)}
                      </h6>
                      <div style={{ width: "100px" }}>
                        <p className="mb-2 text-sm text-gray-400 truncate ...">
                          {transaction.reason}
                        </p>
                      </div>
                    </div>
                    <span
                      className={`text-sm ${getStatusClass(
                        transaction.status
                      )}`}
                    >
                      {getStatusText(transaction.status)}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))
        ) : (
          <div
            className="px-4 py-3 rounded relative"
            role="alert"
            style={{ backgroundColor: "rgb(24 36 80)" }}
          >
            <span className="block sm:inline">
              No transactions found for the selected status.
            </span>
          </div>
        )}
      </div>
      {selectedTransaction && (
        <TransactionModal
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
        />
      )}
    </div>
  );
};

export default WithdrawTransactions;
