// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CompleteHome from './home/CompleteHome';
import CompleteLogin from './home/CompleteLogin';
import CompleteRegister from './home/CompleteRegister';
import CompleteForgotPassword from './home/CompleteForgotPassword';
import GeneratePassword from './home/GeneratePassword';
import DashboardHome from './dashboard/DashboardHome';
import WorkPanel from './dashboard/WorkPanel';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<CompleteHome />} />
          <Route path="/auth/login" element={<CompleteLogin />} />
          <Route path="/auth/create-account" element={<CompleteRegister />} />
          <Route path="/auth/forgot-password" element={<CompleteForgotPassword />} />
          <Route path="/auth/generate-password/:key" element={<GeneratePassword />} />
          {/* Render DashboardHome for any routes under /app */}
          <Route path="/app/*" element={<DashboardHome />} />
          <Route path="/app/work" element={<WorkPanel/>} />
          {/* Add other routes here if needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
