import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, Route, Routes, Navigate, useLocation } from "react-router-dom";

import homeIcon from "../images/img/dashboard/bottom_home.svg";
import payoutIcon from "../images/img/dashboard/bottom_payout.svg";
import profileIcon from "../images/img/dashboard/bottom_profile.svg";
import statsIcon from "../images/img/dashboard/bottom_statistics.svg";
import supportIcon from "../images/img/dashboard/bottom_support.svg";

import Payout from "./Payout";
import Profile from "./Profile";
import Statistics from "./Statistics";
import Support from "./Support";
import Dashboard from "./Dashboard";

const DashboardHome = () => {
  const [user, setUser] = useState(null);
  const location = useLocation(); // Get current location

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/auth/status`;

        const response = await axios.get(apiUrl, { withCredentials: true });
        if (response.data.status === "success") {
          setUser(response.data.user_data);
        } else {
          // Redirect to login if not authenticated
          window.location.href = "../auth/login";
        }
      } catch (error) {
        console.error("Error checking login status:", "Error Found");
      }
    };

    checkLoginStatus();
  }, []);

  // Determine the active tab based on the current location
  const getActiveTab = () => {
    switch (location.pathname) {
      case "/app/payout":
        return "payout";
      case "/app/statistics":
        return "statistics";
      case "/app/home":
        return "home";
      case "/app/support":
        return "support";
      case "/app/profile":
        return "profile";
      default:
        return "home"; // Default active tab
    }
  };

  const activeTab = getActiveTab(); // Get the active tab

  return (
    <div>
      <div className="content">
        <Routes>
          <Route path="home" element={<Dashboard userDetails={user} />} />
          <Route path="payout" element={<Payout userDetails={user} />} />
          <Route path="profile" element={<Profile userDetails={user} />} />
          <Route
            path="statistics"
            element={<Statistics userDetails={user} />}
          />
          <Route path="support" element={<Support userDetails={user} />} />
          {/* Redirect to home if no route matches */}
          <Route path="*" element={<Navigate to="home" />} />
        </Routes>
      </div>
      {/* Footer with navigation links */}
      <footer
        className="fixed bottom-0 left-0 w-full shadow-lg"
        style={{ backgroundColor: "rgb(24, 36, 80)" }}
      >
        <div className="flex justify-around items-center py-2">
          <Link
            to="payout"
            className={`flex flex-col items-center no-underline ${
              activeTab === "payout" ? "text-blue-500" : "text-gray-400"
            }`}
          >
            <img src={payoutIcon} alt="Payout" className="w-6 h-6" />
            <span className="text-xs">Payout</span>
          </Link>
          <Link
            to="statistics"
            className={`flex flex-col items-center no-underline ${
              activeTab === "statistics" ? "text-blue-500" : "text-gray-400"
            }`}
          >
            <img src={statsIcon} alt="Statistics" className="w-6 h-6" />
            <span className="text-xs">Statistics</span>
          </Link>
          <Link
            to="home"
            className={`flex flex-col items-center no-underline ${
              activeTab === "home" ? "text-blue-500" : "text-gray-400"
            }`}
          >
            <img src={homeIcon} alt="Home" className="w-6 h-6" />
            <span className="text-xs">Home</span>
          </Link>
          <Link
            to="support"
            className={`flex flex-col items-center no-underline ${
              activeTab === "support" ? "text-blue-500" : "text-gray-400"
            }`}
          >
            <img src={supportIcon} alt="Support" className="w-6 h-6" />
            <span className="text-xs">Support</span>
          </Link>
          <Link
            to="profile"
            className={`flex flex-col items-center no-underline ${
              activeTab === "profile" ? "text-blue-500" : "text-gray-400"
            }`}
          >
            <img src={profileIcon} alt="Profile" className="w-6 h-6" />
            <span className="text-xs">Profile</span>
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default DashboardHome;
