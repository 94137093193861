// src/components/CompleteHome.js

import React from "react";
import Header from "./Header";
import Home from "./Home";

import "./css/Home.css";

const CompleteHome = () => {
  return (
    <div className="main-theme">
      <Header />
      <Home />
    </div>
  );
};

export default CompleteHome;
