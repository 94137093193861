import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import fireIcon from "../images/img/dashboard/fire.svg";
import paymentIcon from "../images/img/dashboard/check.gif";

const LastPayments = ({ lastPayments }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mt-2">
      <div className="flex justify-between items-center mb-6">
        <h5 className="text-lg font-bold text-white mb-0 inline-flex items-center">
          Successful Payments{" "}
          <img src={fireIcon} width={20} className="ml-2" alt="Fire Icon" />
        </h5>
      </div>

      <div className="swiper-container mt-4">
        <Slider {...settings}>
          {lastPayments.map((payment, index) => (
            <div key={index} className="swiper-slide p-2">
              <div className="bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg shadow-lg p-3 relative transform transition-transform hover:scale-105">
                <div className="absolute top-0 right-0 m-2">
                  <div className="w-8 h-8 bg-blue-400 rounded-full animate-pulse"></div>
                  <div className="w-4 h-4 bg-blue-500 rounded-full mt-1 animate-pulse"></div>
                </div>
                <h6 className="text-lg font-semibold flex items-center">
                  <img src={paymentIcon} width={40} alt="Payment Icon" />{" "}
                  Payment successful!
                </h6>
                <p className="mt-2">
                  to <span className="font-bold">@{payment.username}</span>
                </p>
                <h2 className="text-3xl font-bold mt-2">
                  ${parseFloat(payment.amount).toFixed(4)}
                </h2>
                <small className="text-gray-200 mt-1 block">
                  *Daily Captcha Work Payments
                </small>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default LastPayments;
