import React, { useState } from "react";
import "./css/SocialAd.css"; // Import your customized CSS for styling
import TaskRunner from "./TaskRunner";

const SocialAd = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleCloseAd = () => {
    setIsVisible(false);
  };

  return (
    <>
      <div className={`social-bar ${isVisible ? "slide-down" : "slide-up"}`}>
        <div className="close-ad">
          <button className="close-btn-dismiss" onClick={handleCloseAd}>
            Close Ad
          </button>
        </div>

        <div className="text-center">
          {/* Add your ad content here */}
          <TaskRunner />
        </div>
      </div>
    </>
  );
};

export default SocialAd;
