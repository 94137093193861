import React, { useEffect, useState } from "react";
import axios from "axios";
import WorkHeader from "./WorkHeader";
import LoadingScreen from "./utils/LoadingScreen";
import CaptchaPage from "./CaptchaPage";

const WorkPanel = () => {
  const [user, setUser] = useState(null);
  const [getAvailableBalance, setAvailableBalance] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/auth/status`;

        const response = await axios.get(apiUrl, { withCredentials: true });
        if (response.data.status === "success") {
          setUser(response.data.user_data);
        } else {
          // Redirect to login if not authenticated
          window.location.href = "../auth/login";
        }
      } catch (error) {
        console.error("Error checking login status:", "Error Found");
      }
    };

    checkLoginStatus();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/getAvailableBalance`;

          const response = await axios.post(
            apiUrl,
            { userId: user.user_data.id },
            { withCredentials: true }
          );
          setAvailableBalance(response.data);
        } catch (error) {
          setError("An error occurred while fetching data.");
        }
      };
      fetchData();
    }
  }, [user]);

  if (!getAvailableBalance) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen text-white"
      style={{ backgroundColor: "rgb(15 32 78)" }}
    >
      <main className="main-wrapper">
        <div className="main-container">
          <WorkHeader availableBalance={getAvailableBalance} />
          <CaptchaPage userDetails={user} todayCaptchas={getAvailableBalance} />
        </div>
      </main>
    </div>
  );
};

export default WorkPanel;
