import React from "react";
import { Link } from "react-router-dom";
import telegramIcon from "../images/img/home/telegram.svg";
import instagramIcon from "../images/img/home/instagram.svg";
import youtubeIcon from "../images/img/home/youtube.svg";

const Footer = () => {
  return (
    <footer className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24">
  <div className="mx-auto">
    <div className="flex flex-wrap justify-between items-start">
      {/* Logo and Description */}
      <div className="w-full md:w-1/4 mb-12 md:mb-0 text-left">
        <h2 className="text-2xl font-bold text-white mb-6">
          Daily Captcha Work
        </h2>
        <p className="text-gray-400 mb-6">
          Earn free monthly recharge and cash by completing captchas from the comfort of your home. Join our community and start earning today!
        </p>
        <div className="flex justify-start items-center space-x-4">
          <a target="_blank" rel="noreferrer" href="https://t.me/dailycaptchawork" className="transition-transform duration-300 transform hover:scale-110">
            <img src={telegramIcon} alt="Telegram" className="h-8 w-8" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/dailycaptchawork" className="transition-transform duration-300 transform hover:scale-110">
            <img src={instagramIcon} alt="Instagram" className="h-8 w-8" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@dailycaptchawork" className="transition-transform duration-300 transform hover:scale-110">
            <img src={youtubeIcon} alt="YouTube" className="h-8 w-8" />
          </a>
        </div>
      </div>

      {/* Quick Links */}
      <div className="w-full md:w-1/4 mb-12 md:mb-0 text-left">
        <h3 className="text-2xl font-semibold text-white mb-6">Quick Links</h3>
        <ul className="space-y-4">
          <li><a href="#home" onClick={handleScroll} className="text-gray-300 hover:text-gray-400 transition-colors duration-300 no-underline">Home</a></li>
          <li><a href="#howtowork" onClick={handleScroll} className="text-gray-300 hover:text-gray-400 transition-colors duration-300 no-underline">How to Work</a></li>
          <li><a href="/auth/create-account" className="text-gray-300 hover:text-gray-400 transition-colors duration-300 no-underline">Join Now</a></li>
          <li><Link to="https://publisher.dailycaptchawork.com" className="text-gray-300 hover:text-gray-400 transition-colors duration-300 no-underline">Become a Publisher</Link></li>
        </ul>
      </div>

      {/* Additional Links */}
      <div className="w-full md:w-1/4 mb-12 md:mb-0 text-left">
        <h3 className="text-2xl font-semibold text-white mb-6">More Links</h3>
        <ul className="space-y-4">
          <li><Link to="/" className="text-gray-300 hover:text-gray-400 transition-colors duration-300 no-underline">About Us</Link></li>
          <li><Link to="/contact" className="text-gray-300 hover:text-gray-400 transition-colors duration-300 no-underline">Contact Us</Link></li>
          <li><Link to="/privacy" className="text-gray-300 hover:text-gray-400 transition-colors duration-300 no-underline">Privacy Policy</Link></li>
          <li><Link to="/terms" className="text-gray-300 hover:text-gray-400 transition-colors duration-300 no-underline">Terms & Conditions</Link></li>
        </ul>
      </div>

      {/* Contact Information */}
      <div className="w-full md:w-1/4 mb-12 md:mb-0 text-left">
        <h3 className="text-2xl font-semibold text-white mb-6">Contact Us</h3>
        <p className="text-sm text-gray-400">
          B403 Laburnum Park Magarpatta City<br />
          Hadapsar - Pune-411013<br />
          Maharashtra - India<br /><br />
          <strong>Website:</strong> <a href="https://www.dailycaptchawork.com" className="text-indigo-400 hover:underline">www.dailycaptchawork.com</a><br />
          <strong>Support:</strong> <a href="mailto:support@dailycaptchawork.com" className="text-indigo-400 hover:underline">support@dailycaptchawork.com</a>
        </p>
      </div>
    </div>

    {/* Footer Bottom */}
    <div className="mt-12 pt-8 border-t border-gray-700 text-center">
      <p className="text-gray-500">
        &copy; {new Date().getFullYear()} Daily Captcha Work. All Rights Reserved.
      </p>
    </div>
  </div>
</footer>

  );
};

const handleScroll = (event) => {
  event.preventDefault();

  const targetId = event.currentTarget.getAttribute("href").slice(1);
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export default Footer;
