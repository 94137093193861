import React from "react";

const SmallFooter = () => {
  return (
    <footer className="mx-auto px-2 sm:px-6 lg:px-8">
      <div className="mx-auto">
        {/* Footer Bottom */}
        <div className="mt-12 pt-8 border-t border-gray-700 text-center">
          <p className="text-gray-500">
            &copy; {new Date().getFullYear()} Daily Captcha Work. All Rights
            Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default SmallFooter;
