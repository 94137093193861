import { useState } from "react";

const FAQSection = () => {
  const faqs = [
    {
      question: "How many dollars do we pay on 1000 captchas?",
      answer: "We pay $2 for every 1000 captchas you complete."
    },
    {
      question: "Can I earn a Free Monthly Recharge by completing captchas?",
      answer: "Yes, by completing captchas, you can earn enough to cover your Free Monthly Recharge."
    },
    {
      question: "Is Daily Captcha Work available for users in all countries?",
      answer: "Yes, we welcome users from any country to participate."
    },
    {
      question: "Which payment methods are accepted?",
      answer: "We accept Paytm, UPI, Bank Transfer, PayPal, Bitcoin, and USDT."
    },
    {
      question: "How long does it take to receive my payment?",
      answer: "Payments are typically processed within 2 days, but delays can occur due to financial team issues."
    },
    {
      question: "How can I convert my earnings into a Free Monthly Recharge or Cash?",
      answer: "You can easily convert your captcha earnings into Free Monthly Recharge or Cash by requesting it through our platform."
    },
    {
      question: "What is the minimum payout threshold?",
      answer: "The minimum payout is $1 for Cash. For Recharge, you need to complete the required amount based on your SIM card plan."
    },
    {
      question: "Can I get paid in Cash for completing captchas?",
      answer: "Yes, you can choose to receive your earnings as Cash, directly transferred to your account."
    },
    {
      question: "Are there any fees for requesting a payout?",
      answer: "There are no fees for requesting a payout. You receive 100% of your earnings."
    },
    {
      question: "How do I maximize my earnings on Daily Captcha Work?",
      answer: "Complete tasks accurately and regularly to maximize your earnings and achieve a Free Monthly Recharge or Cash."
    },
    {
      question: "Can I work on Daily Captcha Work from my mobile device?",
      answer: "Yes, our platform is mobile-friendly, allowing you to earn on the go."
    },
    {
      question: "How do I track my progress towards a Free Monthly Recharge?",
      answer: "You can track your earnings in your dashboard to see how close you are to achieving a Free Monthly Recharge."
    },
    {
      question: "What happens if I make a mistake while completing a captcha?",
      answer: "Accuracy is important. Repeated mistakes can lead to lower earnings, so complete tasks carefully."
    },
    {
      question: "How often can I request a payout?",
      answer: "You can request a payout as soon as you reach the minimum threshold of $1."
    },
    {
      question: "Is there a limit to how much I can earn?",
      answer: "Yes, there is a limit of 100 captchas per day. You can complete up to 100 captchas daily to achieve your Free Monthly Recharge and Cash."
    },
    {
      question: "Can I use multiple payment methods for withdrawals?",
      answer: "Yes, you can choose from our various payment methods, including Paytm, UPI, PayPal, and more."
    }
];


  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <section className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24">
      <div className="mb-20 mx-auto max-w-2xl lg:max-w-none text-center space-y-4">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Frequently Asked Questions (FAQs)
        </h2>
        <p className="text-lg leading-8 text-gray-300">
            Explore our FAQs to find helpful information about our services, troubleshooting tips, and more.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {faqs.map((faq, index) => (
          <div key={index} className="p-4 support-border rounded-lg shadow-lg">
            <button
              className="flex items-center justify-between w-full text-left text-gray-400 font-medium focus:outline-none"
              onClick={() => toggleFAQ(index)}
            >
              <span>{faq.question}</span>
              <svg
                className={`w-5 h-5 text-gray-300 transition-transform transform ${
                  openFAQ === index ? "rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`mt-2 text-sm text-gray-400 overflow-hidden transition-max-height duration-500 ease-in-out ${
                openFAQ === index ? "max-h-screen" : "max-h-0"
              }`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
