import React from "react";
import featureOne from "../images/img/home/captcha_box-min.png";
import featureTwo from "../images/img/home/payment_request-min.png";
import featureThree from "../images/img/home/payment_received-min.png";

const OurFeatures = () => {
  return (
    <section
      className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24"
      id="features"
    >
      <div className="mb-12 sm:mb-20 mx-auto max-w-2xl lg:max-w-4xl text-center space-y-4">
        <h2 className="text-3xl sm:text-4xl font-bold tracking-tight text-white">
          Our Features
        </h2>
        <p className="text-md sm:text-lg leading-8 text-gray-300">
          Explore Our Features. See how Daily Captcha Work is making a
          difference for thousands of users!
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Feature Card 1 */}
        <div className="bg-transparent rounded-lg pt-6">
          <div className="flex items-center justify-center mb-4">
            <img
              src={featureOne}
              alt="Captcha Process"
              className="w-48 sm:w-60 mx-auto animated-image"
            />
          </div>
          <h3 className="text-xl sm:text-2xl font-semibold text-white text-center mb-4">
            Easy Captcha Entry
          </h3>
          <p className="text-gray-400 text-center sm:text-base">
            Start earning Free Monthly Recharge and Cash by completing easy
            captchas. Effortlessly earn your Free Monthly Recharge or Cash with
            just a few simple captchas!
          </p>
        </div>

        {/* Feature Card 2 */}
        <div className="bg-transparent rounded-lg pt-6">
          <div className="flex items-center justify-center mb-4">
            <img
              src={featureTwo}
              alt="Request Payment"
              className="w-48 sm:w-60 mx-auto animated-image"
            />
          </div>
          <h3 className="text-xl sm:text-2xl font-semibold text-white text-center mb-4">
            Simple Payment Requests
          </h3>
          <p className="text-gray-400 text-center sm:text-base">
            Convert your captcha efforts into Free Monthly Recharge or Cash!
            Easily request your Free Monthly Recharge or Cashout and enjoy the
            rewards of your hard work.
          </p>
        </div>

        {/* Feature Card 3 */}
        <div className="bg-transparent rounded-lg pt-6">
          <div className="flex items-center justify-center mb-4">
            <img
              src={featureThree}
              alt="Get Money"
              className="w-48 sm:w-60 mx-auto animated-image"
            />
          </div>
          <h3 className="text-xl sm:text-2xl font-semibold text-white text-center mb-4">
            Fast Payouts
          </h3>
          <p className="text-gray-400 text-center sm:text-base">
            Get your Free Monthly Recharge or Cash instantly after requesting!
            Enjoy fast and secure Recharges or Payouts directly to your account
            for the captchas you complete.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurFeatures;
