import React, { useState, useEffect } from "react";
import axios from "axios";
import phoneIcon from "../images/img/dashboard/phone.svg";
import emailIcon from "../images/img/dashboard/email.svg";
import locationIcon from "../images/img/dashboard/location.svg";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    recaptcha: ''
  });
  const [captcha, setCaptcha] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sentMessage, setSentMessage] = useState('');

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.recaptcha !== captcha) {
      setErrorMessage('Invalid captcha. Please enter the correct captcha code.');
      return;
    }

    setLoading(true);
    setErrorMessage('');
    setSentMessage('');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/send-email`;

    axios.post(apiUrl, formData)
      .then(response => {
        setLoading(false);
        setSentMessage('Your message has been sent. We will contact you soon. Thank you!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
          recaptcha: ''
        });
        generateCaptcha(); // Regenerate captcha
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage('Error sending message. Try again later.');
      });
  };

  const generateCaptcha = () => {
    const captchaValue = Math.random().toString(36).substring(2, 6).toUpperCase();
    setCaptcha(captchaValue);
  };

  return (
    <section id="contactus" className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24">
      <div className="mb-20 mx-auto max-w-2xl lg:max-w-none text-center space-y-4">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
          Get In Touch
        </h2>
        <p className="text-lg leading-8 text-gray-300">
          Feel free to contact us! Submit your queries here and we will get back to you as soon as possible.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        <div className="bg-white/10 text-white rounded-lg p-6 flex flex-col items-center shadow-lg">
          <img
            src={locationIcon}
            width={40}
            className="filter invert mb-3"
            alt="Location Icon"
          />
          <h3 className="text-lg font-semibold">Office Address</h3>
          <p className="text-sm text-center mt-2">B403 Laburnum Park Magarpatta CityHadapsar - Pune-411013 Maharashtra - India</p>
        </div>
        <div className="bg-white/10 text-white rounded-lg p-6 flex flex-col items-center shadow-lg">
          <img src={phoneIcon} width={40} className="filter invert mb-3" alt="Phone Icon"/>
          <h3 className="text-lg font-semibold">Call Support</h3>
          <p className="text-sm mt-2">+917977874331</p>
        </div>
        <div className="bg-white/10 text-white rounded-lg p-6 flex flex-col items-center shadow-lg">
          <img src={emailIcon} width={40} className="filter invert mb-3" alt="Email Icon"/>
          <h3 className="text-lg font-semibold">Email Support</h3>
          <p className="text-sm mt-2">support@dailycaptchawork.com</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="rounded-lg overflow-hidden shadow-lg">
          <iframe title="Company Address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1891.623785997708!2d73.92777165820587!3d18.517711190192944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c18c6b16776b%3A0x95200a55b6a5aa5b!2sLABURNUM%20PARK%2C%20Towards%20John%20Deere%20Inner%20Cir%2C%20Magarpatta%2C%20Hadapsar%2C%20Pune%2C%20Maharashtra%20411028!5e0!3m2!1sen!2sin!4v1638990891820!5m2!1sen!2sin" width="100%" height="100%" allowFullScreen="" loading="lazy"></iframe>
        </div>
        <div className="p-2">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-400">
                  Your Name *
                </label>
                <input
                  type="text"
                  name="name"
                  className="mt-1 block w-full px-4 py-2 rounded-lg support-border bg-transparent text-white"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-400">
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  className="mt-1 block w-full px-4 py-2 rounded-lg support-border bg-transparent text-white"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-400">
                  Phone Number *
                </label>
                <input
                  type="text"
                  name="phone"
                  className="mt-1 block w-full px-4 py-2 rounded-lg support-border bg-transparent text-white"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-400">
                  Description *
                </label>
                <textarea
                  name="message"
                  className="mt-1 block w-full px-4 py-2 rounded-lg support-border bg-transparent text-white"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="mt-4">
                <h6 className="mb-2 text-gray-300"><strong>Human Verification:</strong></h6>
                <div className="text-white text-xl">Captcha: {captcha}</div>
                <input
                  type="text"
                  name="recaptcha"
                  className="mt-2 block w-full px-4 py-2 rounded-lg support-border bg-transparent text-white"
                  placeholder="Enter Captcha"
                  value={formData.recaptcha}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-4">
              {loading && <div className="text-yellow-400 ml-2">Sending...</div>}
              {errorMessage && <div className="text-red-500">{errorMessage}</div>}
              {sentMessage && <div className="text-green-500">{sentMessage}</div>}
            </div>
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white p-3 rounded-lg shadow-md hover:bg-indigo-700 transition-colors mt-4"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
