import React from 'react';
import articles from "./stepsArticles"; // Adjust the path as necessary
import BitmediaAd from './BitmediaFloatAd'; // Adjust the import path for your ad component

const Article = () => {
  // Select a random article
  const randomArticle = articles[Math.floor(Math.random() * articles.length)];

  // Split the article content by the placeholder {advertisement}
  const articleContent = randomArticle.content.split(/{advertisement}/g).map(part => part.trim()).filter(part => part);

  return (
    <div className="mb-8 text-gray-500">
      <h3 className="text-2xl font-bold mb-2 text-gray-300">{randomArticle.title}</h3>
      <div className="bg-blue-900 text-white px-1 text-sm py-2 rounded-md shadow-md flex items-center justify-center mb-3">
        <span>⬇️ Scroll Down for Next Step ⬇️</span>
      </div>
      {articleContent.map((part, index) => (
        <React.Fragment key={index}>
          {/* Render the HTML part */}
          <div dangerouslySetInnerHTML={{
            __html: part // Render the HTML content
          }} />

          {/* Render the ad component after every part except the last one */}
          {index < articleContent.length - 1 && <BitmediaAd/>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Article;
