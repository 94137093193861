import React from "react";
import Typewriter from "../utils/Typewriter"; // Adjust path as necessary
import heroImg from "../images/img/home.svg";
import coinbyte from "../images/img/home/sammy-coin-min.gif";
import TaskRunner from "./TaskRunner";

const Hero = () => {
  const texts = [
    "Complete captchas and earn Free Mobile Recharge with DCW!",
    "Unlock Free Mobile Recharge by solving captchas on our platform!",
    "Earn Free Mobile Recharge every month by typing captchas online!",
    "Your path to Free Mobile Recharge starts with solving captchas!",
    "Solve captchas to help cover your Free Mobile Recharge and extra monthly expenses!",
  ];

  return (
    <section
      id="home"
      className="pt-16 md:pt-32 lg:pt-32 hero-section z-10 relative"
      style={{ minHeight: "100vh" }}
    >
      <TaskRunner/>
      <div className="flex flex-col md:flex-row items-center">
        <div className="w-full md:w-7/12 flex flex-col justify-center pl-2 pr-2 mb-2 mt-5">
          <h1 className="mb-10 text-4xl md:text-5xl lg:text-6xl font-black welcome-home-message">
            Get Free Monthly Recharge and Cash with DCW
            <img
              src={coinbyte}
              alt="Coin"
              width={40}
              className="inline-block ml-2 align-middle md:w-12 lg:w-16"
            />
          </h1>

          <h1
            className="mb-4 text-[#c6a2ff] font-black text-xl md:text-2xl lg:text-3xl w-full md:w-10/12"
            style={{ minHeight: "120px" }}
          >
            <Typewriter texts={texts} typingSpeed={100} />
          </h1>

          <h1
            className="mb-3 text-3xl md:text-4xl lg:text-6xl font-black recharge-home-message"
            style={{ minHeight: "130px" }}
          >
            <Typewriter
              texts={["Free Mobile Recharge & Cash!"]}
              typingSpeed={100}
            />
          </h1>

          <p className="text-lg text-gray-300 md:text-xl lg:text-xl">
            Earn money easily with captcha typing jobs available for everyone.
          </p>

          <div className="flex justify-start space-x-4 mt-8">
            <a
              href="/auth/login"
              className="no-underline text-center bg-gradient-to-r from-blue-500 to-blue-700 text-white hover:from-blue-600 hover:to-blue-800 login-home-btn w-1/2 md:w-1/4 py-3 rounded-full lato-bold transition-all duration-200"
            >
              Login
            </a>
            <a
              href="/auth/create-account"
              className="no-underline text-center bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 text-white join-now-btn w-1/2 md:w-1/4 py-3 rounded-full lato-bold transition-all duration-200"
            >
              Join Now
            </a>
          </div>
        </div>

        <div className="w-full md:w-5/12 mt-8 md:mt-0">
          <img src={heroImg} className="w-full mx-auto" alt="Hero" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
