import React, { useEffect, useState } from "react";
import axios from "axios";

import DashboardHeader from "./DashboardHeader";
import Welcome from "./Welcome";
import Footer from "./Footer";
import SupportPage from "./SupportPage";
import LoadingScreen from "./utils/LoadingScreen";

const Support = ({ userDetails }) => {
  const [tickets, setTickets] = useState(null);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/getTickets`;

      const response = await axios.post(
        apiUrl,
        { userId: userDetails.user_data.id },
        { withCredentials: true }
      );
      setTickets(response.data);
    } catch (error) {
      setError("An error occurred while fetching data.");
    }
  };

  useEffect(() => {
    if (userDetails) {
      fetchData();
    }
  }, [userDetails]);

  if (!tickets) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen text-white"
      style={{ backgroundColor: "rgb(14 28 64)", paddingBottom: "70px" }}
    >
      <main className="main-wrapper">
        <div className="main-container">
          <DashboardHeader />
          <Welcome userDetails={userDetails} />
          <SupportPage
            tickets={tickets}
            userDetails={userDetails}
            refreshTickets={fetchData}
          />
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default Support;
