// src/components/CompleteHome.js

import React from 'react';
import Header from './Header';
import ForgotPassword from './ForgotPassword';

const CompleteForgotPassword = () => {
  return (
    <>
      <Header />
      <ForgotPassword />
    </>
  );
}

export default CompleteForgotPassword;
