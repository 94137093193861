import React from "react";
import playBg from "../images/img/home/play_bg-min.png";
import captchaTyping from "../images/img/home/captcha_typing-min.png";
import onlineGames from "../images/img/home/online_games-min.png";
import surveys from "../images/img/home/surveys-min.png";

const WaysOfEarning = () => {
  return (
    <section className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24">
      <div className="mb-20 mx-auto max-w-2xl lg:max-w-none text-center space-y-4">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
          Ways of Earning
        </h2>
        <p className="text-lg leading-8 text-gray-300">
          Explore various ways to earn: type captchas, play online games,
          complete surveys, and participate in quiz games!
        </p>
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Card 1: Captcha Tasks */}
        <div className="relative rounded-lg shadow-md overflow-hidden flex flex-col justify-between min-h-[180px]">
          <div className="absolute inset-0 bg-blue-900 opacity-20 rounded-lg"></div>
          <div className="relative z-10 flex items-center p-4 rounded-lg">
            <div className="flex-shrink-0 p-2">
              <img
                src={captchaTyping}
                alt="Captcha Typing"
                width={100}
                className="-rotate-12 duration-200 hover:scale-110"
              />
            </div>
            <div className="ml-3">
              <h5 className="text-md font-semibold text-white">
                Captcha Tasks
              </h5>
              <p className="text-gray-400 text-sm">
                Quick earnings, easy tasks.
              </p>
            </div>
          </div>
          <p className="text-green-300 text-center mt-3 relative z-10 mb-3">
            Active
          </p>
        </div>

        {/* Card 2: Online Games */}
        <div className="relative rounded-lg shadow-md overflow-hidden flex flex-col justify-between min-h-[180px]">
          <div className="absolute inset-0 bg-green-900 opacity-20 rounded-lg"></div>
          <div className="relative z-10 flex items-center p-4 rounded-lg">
            <div className="flex-shrink-0 p-2">
              <img
                src={onlineGames}
                alt="Online Games"
                width={50}
                className="duration-200 hover:scale-110"
              />
            </div>
            <div className="ml-3">
              <h5 className="text-md font-semibold text-white">Online Games</h5>
              <p className="text-gray-400 text-sm">
                Play online games and earn rewards
              </p>
            </div>
          </div>
          <p className="text-gray-300 text-center relative z-10 mb-3">
            Coming Soon
          </p>
        </div>

        {/* Card 3: Quiz Games */}
        <div className="relative rounded-lg shadow-md overflow-hidden flex flex-col justify-between min-h-[180px]">
          <div className="absolute inset-0 bg-red-900 opacity-20 rounded-lg"></div>
          <div className="relative z-10 flex items-center p-4 rounded-lg">
            <div className="flex-shrink-0 p-2">
              <img
                src={surveys}
                alt="Quiz Games"
                width={70}
                className="duration-200 hover:scale-110"
              />
            </div>
            <div className="ml-3">
              <h5 className="text-md font-semibold text-white">Quiz Games</h5>
              <p className="text-gray-400 text-sm">
                Play quiz games and earn rewards
              </p>
            </div>
          </div>
          <p className="text-gray-300 text-center relative z-10 mb-3">
            Coming Soon
          </p>
        </div>

        {/* Card 4: Video Ads */}
        <div className="relative rounded-lg shadow-md overflow-hidden flex flex-col justify-between min-h-[180px]">
          <div className="absolute inset-0 bg-yellow-900 opacity-20 rounded-lg"></div>
          <div className="relative z-10 flex items-center p-4 rounded-lg">
            <div className="flex-shrink-0 p-2">
              <img
                src={playBg}
                alt="Video Ads"
                width={60}
                className="duration-200 hover:scale-110"
              />
            </div>
            <div className="ml-3">
              <h5 className="text-md font-semibold text-white">Video Ads</h5>
              <p className="text-gray-400 text-sm">Watch Video Ads</p>
            </div>
          </div>
          <p className="text-gray-300 text-center relative z-10 mb-3">
            Coming Soon
          </p>
        </div>
      </div>
    </section>
  );
};

export default WaysOfEarning;
