import React from 'react';
import '../css/ProgressBar.css';

const ProgressBar = () => {
  return (
    <div className="progress-bar-container">
      <progress className="pure-material-progress-linear" />
    </div>
  );
};

export default ProgressBar;
