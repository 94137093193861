import React, { useState, useEffect } from "react";
import axios from "axios";

// header icons
import walletIcon from "../images/img/dashboard/wallet.svg";

const WithdrawPage = ({ paymentDetails, userDetails }) => {
  const [withdraw, setWithdraw] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    if (userDetails) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/requestWithdraw`;

        const response = await axios.post(
          apiUrl,
          { userId: userDetails.user_data.id },
          { withCredentials: true }
        );
        setWithdraw(response.data);
      } catch (error) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const handleWithdrawRequest = () => {
    setLoading(true); // Start loading
    setShowAlert(true);
    fetchData();
    setIsModalOpen(false);
  };

  useEffect(() => {
    const logout = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/auth/logout`;

        await axios.post(apiUrl, {}, { withCredentials: true });
      } catch (error) {
        console.error("Error during logout:", "Error Found");
      }
    };

    if (withdraw && withdraw.status === "success") {
      logout();

      // Redirect to auth/login after 3 seconds
      setTimeout(() => {
        window.location.href = "/auth/login";
      }, 3000);
    } else {
      // Hide the alert after 3 seconds if the status is not "success"
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [withdraw]);

  const getPercentage = (percentage) => {
    if (percentage >= 100) {
      return 100;
    } else {
      return percentage;
    }
  };

  if (error) {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-4">
      <div className="rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 m-1">
        {withdraw && showAlert && (
          <div
            className="px-4 py-3 rounded relative mb-3"
            role="alert"
            style={{
              backgroundColor:
                withdraw.status === "success"
                  ? "rgb(68 118 67)"
                  : "rgb(32 61 171)",
            }}
          >
            <span className="block sm:inline">{withdraw.message}</span>
          </div>
        )}

        {isModalOpen && (
          <div
            className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 p-4 ${
              isModalOpen ? "modal-enter" : "modal-exit"
            }`}
          >
            <div
              className="rounded-lg shadow-lg p-6 transform transition-transform duration-300 ease-in-out"
              style={{ backgroundColor: "rgb(24, 36, 80)" }}
            >
              <h2 className="text-lg font-semibold mb-4">
                You are requesting a payout.
              </h2>
              <h4 className="text-sm font-semibold mb-4">
                This action will log you out of your current session.
              </h4>
              <div className="flex justify-end">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleWithdrawRequest}
                  className="px-4 py-2 text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="rounded-t-lg bg-white pt-2 pb-2 pl-4">
          <h3 className="text-lg font-bold text-dark mb-0">Your earnings</h3>
        </div>
        <div
          style={{ backgroundColor: "rgb(24 36 80)" }}
          className="p-6 rounded-b-lg"
        >
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            Withdraw once you reach your payment (threshold of $1).
          </p>
          <div className="payout-container">
            <div className="flex flex-col md:flex-row items-center mb-3 mt-3">
              <div className="w-full md:w-3/4">
                <div className="bg-gray-300 h-5 rounded-full">
                  <div
                    className="bg-blue-500 h-5 rounded-full"
                    style={{
                      width:
                        getPercentage(paymentDetails.payment_percentage) + "%",
                    }}
                  ></div>
                </div>
              </div>
              <div className="w-full md:w-1/4 flex justify-center mt-3 md:mt-0">
                <div className="text-center">
                  <h3 className="text-white font-extrabold">
                    ${parseFloat(paymentDetails.payment_wallet).toFixed(4)}
                  </h3>
                </div>
              </div>
            </div>
            <div className="flex justify-between text-gray-400">
              <p>
                You've reached <br />{" "}
                <center>
                  {parseFloat(paymentDetails.payment_percentage).toFixed(2)}%
                </center>
              </p>
              <p>
                Payment threshold: <br />{" "}
                <center>${paymentDetails.payment_threshold}</center>
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={() => setIsModalOpen(true)}
            disabled={loading} // Disable button while loading
            className="flex items-center justify-center w-full px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-full hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 no-underline"
          >
            {loading && (
              <span
                className="spinner-grow spinner-grow-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Withdraw{" "}
            <img
              src={walletIcon}
              width={18}
              className="m-1 mb-1"
              alt="Wallet Icon"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawPage;
