import React, { useState } from "react";
import playBg from "../images/img/home/play_bg-min.png";

const HowToWork = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const iframeSrc = iframe.src;
      iframe.src = iframeSrc; // Reset the src to stop the video
    }
    setIsModalOpen(false);
  };

  return (
    <section
      id="howtowork"
      className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24"
    >
      {/* Title Section */}
      <div className="mb-20 mx-auto max-w-2xl lg:max-w-none text-center space-y-4">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
          How To Work
        </h2>
        <p className="text-lg leading-8 text-gray-300">
          See why so many users trust Daily Captcha Work and find out how it
          benefits you!
        </p>
      </div>

      {/* Content Section */}
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        {/* Video Section */}
        <div
          className="w-full lg:w-1/2 flex items-center justify-center mb-8 lg:mb-0"
          style={{
            backgroundImage: `url(${playBg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div className="relative w-full h-80 lg:h-full xl:h-[550px] rounded-lg overflow-hidden">
            <div className="absolute inset-0 bg-blue-900 opacity-30 rounded-lg"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <div
                className="bg-white p-4 rounded-full cursor-pointer transform transition duration-500 hover:scale-110"
                onClick={openModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-10 h-10 text-blue-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14.752 11.168l-5.197-2.998A1 1 0 008 9.135v5.73a1 1 0 001.555.832l5.197-2.998a1 1 0 000-1.664z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* Cards Section */}
        <div className="w-full lg:w-1/2 lg:flex items-center justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
            {/* Card 1 */}
            <div className="relative rounded-lg shadow-md overflow-hidden">
              <div className="absolute inset-0 bg-blue-900 opacity-20 rounded-lg"></div>
              <div className="relative z-10 flex items-center p-4 rounded-lg">
                <div className="flex-shrink-0">
                  <div className="bg-green-500 p-2 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-3">
                  <h5 className="text-md font-semibold text-white">
                    Register an Account
                  </h5>
                  <p className="text-gray-400 text-sm">
                    Sign up with your details to start working.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="relative rounded-lg shadow-md overflow-hidden">
              <div className="absolute inset-0 bg-blue-900 opacity-20 rounded-lg"></div>
              <div className="relative z-10 flex items-center p-4 rounded-lg">
                <div className="flex-shrink-0">
                  <div className="bg-green-500 p-2 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-3">
                  <h5 className="text-md font-semibold text-white">
                    Choose Your Captcha
                  </h5>
                  <p className="text-gray-400 text-sm">
                    Select a captcha task to begin earning.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="relative rounded-lg shadow-md overflow-hidden">
              <div className="absolute inset-0 bg-blue-900 opacity-20 rounded-lg"></div>
              <div className="relative z-10 flex items-center p-4 rounded-lg">
                <div className="flex-shrink-0">
                  <div className="bg-green-500 p-2 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-3">
                  <h5 className="text-md font-semibold text-white">
                    Solve Captchas
                  </h5>
                  <p className="text-gray-400 text-sm">
                    Enter the correct text for the displayed captcha.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 4 */}
            <div className="relative rounded-lg shadow-md overflow-hidden">
              <div className="absolute inset-0 bg-blue-900 opacity-20 rounded-lg"></div>
              <div className="relative z-10 flex items-center p-4 rounded-lg">
                <div className="flex-shrink-0">
                  <div className="bg-green-500 p-2 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-3">
                  <h5 className="text-md font-semibold text-white">
                    Earn Money
                  </h5>
                  <p className="text-gray-400 text-sm">
                    Collect money with each completed captcha.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 5 */}
            <div className="relative rounded-lg shadow-md overflow-hidden">
              <div className="absolute inset-0 bg-blue-900 opacity-20 rounded-lg"></div>
              <div className="relative z-10 flex items-center p-4 rounded-lg">
                <div className="flex-shrink-0">
                  <div className="bg-green-500 p-2 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-3">
                  <h5 className="text-md font-semibold text-white">
                    Withdraw Earnings
                  </h5>
                  <p className="text-gray-400 text-sm">
                    Transfer your earnings to your account.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 6 */}
            <div className="relative rounded-lg shadow-md overflow-hidden">
              <div className="absolute inset-0 bg-blue-900 opacity-20 rounded-lg"></div>
              <div className="relative z-10 flex items-center p-4 rounded-lg">
                <div className="flex-shrink-0">
                  <div className="bg-green-500 p-2 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-3">
                  <h5 className="text-md font-semibold text-white">
                    Earn Real Money
                  </h5>
                  <p className="text-gray-400 text-sm">
                    Receive your money quickly and enjoy it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for video */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex items-center justify-center">
          <div className="relative w-full max-w-4xl">
            <iframe
              className="w-full h-[400px] sm:h-[500px] lg:h-[600px]"
              src="https://www.youtube.com/embed/0va5NLFOvGs?si=eV5UIjQbiz8vRMDw&amp;controls=0"
              title="YouTube video player"
              allowFullScreen
            ></iframe>
            <button
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default HowToWork;
