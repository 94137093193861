import React, { useState, useEffect } from "react";
import paymentSuccessIcon from "../../images/img/dashboard/check.gif";
import paymentPendingIcon from "../../images/img/dashboard/pending.svg";
import paymentCancelledIcon from "../../images/img/dashboard/cancelled.svg";

const TransactionModal = ({ transaction, onClose }) => {
  const [animationClass, setAnimationClass] = useState("modal-enter");

  useEffect(() => {
    setAnimationClass("modal-enter");
  }, [transaction]);

  const handleClose = () => {
    setAnimationClass("modal-exit");
    setTimeout(() => {
      onClose();
    }, 300); // Match the duration of the animation
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Cancelled";
      case 3:
        return "Approved";
      default:
        return "Unknown";
    }
  };

  if (!transaction) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
      <div
        style={{ backgroundColor: "rgb(24, 36, 80)", width: '90%' }}
        className={`rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg ${animationClass}`}
      >
        <div className="p-6">
          <h4 className="text-xl font-bold mb-4">Transaction Details</h4>
          <hr />
          <div class="w-full text-center" style={{height: '70px'}}>
            {transaction.status === 3 ? (
              <img
                src={paymentSuccessIcon}
                width={50}
                alt="Payment Icon 1"
                className="m-auto d-block"
              />
            ) : transaction.status === 1 ? (
              <img
                src={paymentPendingIcon}
                width={40}
                alt="Payment Icon 2"
                className="m-auto d-block"
              />
            ) : transaction.status === 2 ? (
              <img
                src={paymentCancelledIcon}
                width={45}
                alt="Payment Icon 3"
                className="m-auto d-block"
              />
            ) : null}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-left font-bold">ID:</div>
            <div className="text-right font-bold">#{transaction.id}</div>
            <div className="text-left font-bold">Method:</div>
            <div className="text-right font-bold">{transaction.method_name}</div>
            <div className="text-left font-bold">Amount:</div>
            <div className="text-right font-bold">${parseFloat(transaction.amount).toFixed(4)}</div>
            <div className="text-left font-bold">Reason:</div>
            <div className="text-right font-bold">{transaction.reason}</div>
            <div className="text-left font-bold">Status:</div>
            <div className="text-right font-bold">{getStatusText(transaction.status)}</div>
          </div>
          <div className="text-right">
            <button
              onClick={handleClose}
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionModal;
