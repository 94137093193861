import React, { useState, useEffect } from "react";
import axios from "axios";
import paymentSuccessIcon from "../images/img/dashboard/check.gif";

// SVG for the blue tick
const BlueTickSVG = () => (
  <img src={paymentSuccessIcon} width={20} alt="Payment Verified" />
);

const PaymentProofs = () => {
  // Initialize with dummy payment proofs
  const [paymentProofs, setPaymentProofs] = useState([
    { id: 0, username: "Loading...", amount: "0.00" },
    { id: 0, username: "Loading...", amount: "0.00" },
    { id: 0, username: "Loading...", amount: "0.00" },
    { id: 0, username: "Loading...", amount: "0.00" },
    { id: 0, username: "Loading...", amount: "0.00" },
    { id: 0, username: "Loading...", amount: "0.00" },
  ]);

  useEffect(() => {
    // Function to fetch recent payments
    const fetchPayments = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/getRecentPayments`;

        const response = await axios.post(apiUrl, { isRecentPayments: true });
        if (response.data && response.data.last_30_payments) {
          setPaymentProofs(response.data.last_30_payments); // Adjust to match your API response structure
        }
      } catch (error) {
        console.error("Error fetching payments:", "Error Found");
      }
    };

    fetchPayments();
  }, []);

  const maskUsername = (username) => {
    if (username.length <= 2) return username; // If the username is too short, don't mask it

    const firstPart = username.slice(0, username.length / 2 - 1);
    const middlePart = "**";
    const lastPart = username.slice(username.length / 2 + 1);

    return `${firstPart}${middlePart}${lastPart}`;
  };

  return (
    <section
      id="paymentproofs"
      className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24"
    >
      <div className="mb-20 mx-auto max-w-2xl lg:max-w-none text-center space-y-4">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
          Payment Proofs
        </h2>
        <p className="text-lg leading-8 text-gray-300">
          View recent payment transactions and proof of successful payments
          here.
        </p>
      </div>
      <div className="mx-auto">
        <div className="relative overflow-hidden">
          <div className="flex animate-scroll">
            {paymentProofs.map((proof, index) => (
              <div
                key={index}
                className="bg-white/10 text-white shadow-lg rounded-lg p-4 flex-none w-72 mx-2 transform transition-transform duration-300 hover:scale-105 flex flex-col items-center justify-center relative"
              >
                <h2 className="text-lg font-semibold mb-1">
                  {maskUsername(proof.username)}
                </h2>
                <p className="text-xl font-bold mb-2">
                  ${parseFloat(proof.amount).toFixed(2)}
                </p>
                <p className="text-sm text-gray-400 mt-1">
                  Transaction ID: #{proof.id}
                </p>

                {/* Blue tick and Verified text */}
                <div className="absolute bottom-2 right-2 flex items-center space-x-1">
                  <BlueTickSVG />
                  <span className="text-xs text-blue-500">
                    Verified Payment
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentProofs;
