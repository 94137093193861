// src/components/CompleteHome.js

import React from 'react';
import Header from './Header';
import Login from './Login';
import SocialAd from './SocialAd';

const CompleteLogin = () => {
  return (
    <>
      <Header/>
      <Login/>
      <SocialAd/>
    </>
  );
}

export default CompleteLogin;
