import React, { useEffect, useState } from "react";
import axios from "axios";
// header icons
import walletIcon from "../images/img/dashboard/wallet.svg";
import Spinner from "./utils/Spinner";

const Welcome = ({ userDetails }) => {
  const [getAvailableBalance, setAvailableBalance] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (userDetails) {
      const fetchData = async () => {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/getAvailableBalance`;

          const response = await axios.post(
            apiUrl,
            { userId: userDetails.user_data.id },
            { withCredentials: true }
          );
          setAvailableBalance(response.data);
        } catch (error) {
          setError("An error occurred while fetching data.");
        }
      };
      fetchData();
    }
  }, [userDetails]);

  if (!getAvailableBalance) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto mb-3 pt-5" style={{ marginTop: "40px" }}>
      <div className="flex justify-between items-center text-white rounded-lg">
        <div>
          <span className="block text-sm text-gray-500">Good Afternoon.</span>
          <h4 className="primary-text mt-2 text-xl font-bold tracking-tight text-white">
            {userDetails.user_data.username} 👋
          </h4>
        </div>
        <div className="text-center">
          {/* Add your SVG here */}
          <span className="block text-lg font-semibold mb-1 text-white">
            <img
              src={walletIcon}
              width={23}
              alt="wallet"
              className="inline-block mb-1 mr-1"
            />
            ${parseFloat(getAvailableBalance.available_balance).toFixed(4)}
          </span>
          <span className="block text-sm text-gray-400">Available Balance</span>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
