import React from "react";
import walletIcon from "../images/img/dashboard/wallet.svg";
import homeIcon from "../images/img/dashboard/home.svg";

const WorkHeader = ({ availableBalance }) => {
  return (
    <header
      className="flex justify-between items-center p-3 text-white fixed top-0 w-full"
      style={{ backgroundColor: "rgb(14, 28, 64)" }} // Set fixed height
    >
      <nav className="flex justify-between w-full items-center">
        {" "}
        {/* Flex for horizontal alignment */}
        <div className="flex items-center space-x-2">
          {" "}
          {/* Flex for image and text */}
          <img
            src={walletIcon}
            alt="Available Balance"
            className="w-5 h-5" // Smaller size
          />
          <a
            href="/app/payout"
            className="text-white no-underline" // White text color
          >
            Balance:{" "}
            {availableBalance
              ? parseFloat(availableBalance.available_balance).toFixed(4)
              : "0.00"}
          </a>
        </div>
        <div className="flex items-center space-x-2">
          {" "}
          {/* Flex for image and text */}
          <img
            src={homeIcon}
            alt="Home"
            className="w-6 h-6 filter invert" // Smaller size
          />
          <a
            href="/app/home"
            className="text-white no-underline" // White text color
          >
            Home
          </a>
        </div>
      </nav>
    </header>
  );
};

export default WorkHeader;
