import React, { useState, useEffect } from "react";
import axios from "axios";

import "./css/Support.css"; // Import custom CSS for hiding scrollbars
import avatarIcon from "../images/img/dashboard/profile_avatar.gif"; // Replace with the avatar image path
import phoneIcon from "../images/img/dashboard/phone.svg"; // Replace with the avatar image path
import emailIcon from "../images/img/dashboard/email.svg"; // Replace with the avatar image path
import locationIcon from "../images/img/dashboard/location.svg"; // Replace with the avatar image path
import fireIcon from "../images/img/dashboard/fire.svg";
import walletIcon from "../images/img/dashboard/wallet.svg";

// payments
import paytmIcon from "../images/img/payments/paytm.svg"; // Example client image import
import upiIcon from "../images/img/payments/upi.svg"; // Example client image import
import paypalIcon from "../images/img/payments/paypal.svg"; // Example client image import

const ProfileTabs = ({
  personal,
  paymentSettings,
  userDetails,
  refreshPersonalDetails,
  refreshPaymentSettings,
}) => {
  const [activeTab, setActiveTab] = useState("profile");
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(null);
  const [updateMethod, setUpdateMethod] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(0);
  const [paymentAddress, setPaymentAddress] = useState("");
  const [del, setDel] = useState(null);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [showAlert, setShowAlert] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const fetchData = async () => {
    if (userDetails) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/updateProfile`;

        const response = await axios.post(
          apiUrl,
          {
            userId: userDetails.user_data.id,
            mobile: mobile,
            email: email,
            address: address,
          },
          { withCredentials: true }
        );
        setUpdateProfile(response.data);

        if (response.data.status === "success") {
          setIsEditing(false);
          setMobile("");
          setAddress("");
          refreshPersonalDetails();
          // Hide the alert after 3 seconds if the status is not "success"
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const handleProfileUpdate = () => {
    setLoading(true); // Start loading
    setShowAlert(true);
    fetchData();
  };

  const deleteAccount = async () => {
    if (userDetails) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/deleteAccount`;

        const response = await axios.post(
          apiUrl,
          { userId: userDetails.user_data.id },
          { withCredentials: true }
        );
        setDel(response.data);
      } catch (error) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const handleDeleteAccount = () => {
    // Add your delete account logic here
    setIsModalOpen(false);
    deleteAccount();
  };

  useEffect(() => {
    const logout = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/auth/logout`;

        await axios.post(apiUrl, {}, { withCredentials: true });
      } catch (error) {
        console.error("Error during logout:", "Error Found");
      }
    };

    if (del && del.status === "success") {
      logout();

      // Redirect to auth/login after 3 seconds
      setTimeout(() => {
        window.location.href = "/auth/login";
      }, 2000);
    } else {
      // Hide the alert after 3 seconds if the status is not "success"
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [del]);

  useEffect(() => {
    if (personal && personal.data) {
      setMobile(personal.data.phone_number);
      setEmail(personal.data.email);
      setAddress(personal.data.address1);
    }
  }, [personal]);

  // code for payment method
  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    if (value !== 0) {
      setSelectedMethod(value);
    }
  };

  const updatePaymentMethod = async () => {
    if (userDetails) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/updatePaymentMethod`;

        const response = await axios.post(
          apiUrl,
          { userId: userDetails.user_data.id, selectedMethod, paymentAddress },
          { withCredentials: true }
        );
        setUpdateMethod(response.data);
      } catch (error) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const handleMethodUpdate = () => {
    setLoading(true); // Start loading
    setShowAlert(true);
    updatePaymentMethod();
  };

  useEffect(() => {
    if (updateMethod && updateMethod.status === "success") {
      // Redirect to auth/login after 3 seconds
      refreshPaymentSettings();
    } else {
      // Hide the alert after 3 seconds if the status is not "success"
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [updateMethod]);

  if (error) {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div className="w-full container">
      <div className="tab-slide-effect">
        <ul className="flex p-0">
          <li
            className={`nav-item w-1/2 ${
              activeTab === "profile" ? "border-b-2 border-white" : ""
            }`}
          >
            <button
              className={`nav-link w-full px-2 py-3`}
              style={{ color: activeTab === "profile" ? "white" : "gray" }}
              onClick={() => handleTabClick("profile")}
            >
              Profile
            </button>
          </li>
          <li
            className={`nav-item w-1/2 ${
              activeTab === "payment" ? "border-b-2 border-white" : ""
            }`}
          >
            <button
              className={`nav-link w-full px-2 py-3`}
              style={{ color: activeTab === "payment" ? "white" : "gray" }}
              onClick={() => handleTabClick("payment")}
            >
              Payment Settings
            </button>
          </li>
        </ul>
      </div>

      <div className="tab-content-container">
        <div
          className={`tab-pane ${
            activeTab === "profile" ? "block" : "hidden"
          } p-4`}
        >
          <div className="profile text-center">
            <div className="relative inline-block w-24 h-24 mb-3">
              <img src={avatarIcon} alt="Avatar" className={`rounded-circle`} />
              <svg
                className="absolute inset-0 w-full h-full progress-style"
                viewBox="0 0 120 120"
              >
                <circle
                  cx="60"
                  cy="60"
                  r="50"
                  stroke="#E8EFF3"
                  strokeWidth="7"
                  fill="none"
                ></circle>
                <circle
                  cx="60"
                  cy="60"
                  r="50"
                  stroke="#C3AC58"
                  strokeWidth="7"
                  fill="none"
                ></circle>
              </svg>
            </div>
            <div className="mb-1">
              <h4 className="text-pretty font-bold">Hi, Demo Account 👋</h4>
              <h6 className="text-gray-500 font-semibold">DCW MEMBER</h6>
            </div>
          </div>

          {updateProfile && showAlert && (
            <div className="mt-3">
              <div
                className="px-4 py-3 rounded relative mb-3"
                role="alert"
                style={{
                  backgroundColor:
                    updateProfile.status === "success"
                      ? "rgb(68 118 67)"
                      : "rgb(32 61 171)",
                }}
              >
                <span className="block sm:inline">{updateProfile.message}</span>
              </div>
            </div>
          )}

          <div className="contact-section mt-6">
            <div className="flex justify-between items-center">
              <h5 className="font-bold text-lg">Contacts</h5>
              <button
                onClick={handleEditClick}
                className="text-blue-500 no-underline"
              >
                {isEditing ? "Cancel" : "Edit"}
              </button>
            </div>
            <form className="profile-form mt-4">
              <ul className="pl-1">
                <li className="mb-4">
                  <div className="flex items-center">
                    <div className="icon-box w-8 h-8 bg-gray-200 rounded-full flex justify-center items-center">
                      <img src={phoneIcon} width={18} alt="Phone Icon" />
                    </div>
                    <div className="ml-3 flex-1 custom-placeholder">
                      <div className="text-gray-500">Mobile Phone</div>
                      {!isEditing ? (
                        <p className="mb-0">{personal.data.phone_number}</p>
                      ) : (
                        <input
                          type="text"
                          name="phone"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          className="w-full transaction-btn-border p-3 rounded-lg mt-2 bg-transparent"
                          placeholder="Mobile Phone"
                        />
                      )}
                    </div>
                  </div>
                </li>
                <li className="mb-4">
                  <div className="flex items-center">
                    <div className="icon-box w-8 h-8 bg-gray-200 rounded-full flex justify-center items-center">
                      <img src={emailIcon} width={18} alt="Email Icon" />
                    </div>
                    <div className="ml-3 flex-1 custom-placeholder">
                      <div className="text-gray-500">Email Address</div>
                      {!isEditing ? (
                        <p className="mb-0">{personal.data.email}</p>
                      ) : (
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full transaction-btn-border p-3 rounded-lg mt-2 bg-transparent"
                          placeholder="Email Address"
                        />
                      )}
                    </div>
                  </div>
                </li>
                <li className="mb-4">
                  <div className="flex items-center">
                    <div className="icon-box w-8 h-8 bg-gray-200 rounded-full flex justify-center items-center">
                      <img src={locationIcon} width={18} alt="Location Icon" />
                    </div>
                    <div className="ml-3 flex-1 custom-placeholder">
                      <div className="text-gray-500">Address</div>
                      {!isEditing ? (
                        <p className="mb-0">{personal.data.address1}</p>
                      ) : (
                        <textarea
                          name="address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          className="w-full transaction-btn-border p-3 rounded-lg mt-2 bg-transparent"
                          placeholder="Address"
                          rows="4"
                        />
                      )}
                    </div>
                  </div>
                </li>
                <li className="mt-4">
                  <button
                    onClick={handleProfileUpdate}
                    type="button"
                    className={`flex items-center justify-center w-full px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-full hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 no-underline ${
                      isEditing ? "" : "cursor-not-allowed opacity-50"
                    }`}
                    disabled={!isEditing || loading}
                  >
                    {loading && (
                      <span
                        className="spinner-grow spinner-grow-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    {isEditing ? "Save changes" : "Edit"}{" "}
                    <img
                      src={fireIcon}
                      width={15}
                      className="ml-1"
                      alt="Fire Icon"
                    />
                  </button>
                </li>
                <li className="mt-4 text-center">
                  <div className="d-flex justify-center">
                    <button
                      type="button"
                      className="text-red-500 text-center block no-underline"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Delete an account
                    </button>
                  </div>
                  <small className="text-gray-500 text-center">
                    This will delete your account.
                  </small>
                </li>
              </ul>
            </form>
          </div>
        </div>

        <div
          className={`tab-pane ${
            activeTab === "payment" ? "block" : "hidden"
          } p-2`}
        >
          {/* check if selected payment method is null */}
          {paymentSettings.data.preferredMethod == null ? (
            <div
              className="px-4 py-3 rounded relative"
              role="alert"
              style={{ backgroundColor: "rgb(203 78 30)" }}
            >
              <span className="block sm:inline text-white">
                {"❕ Please update your payment method!"}
              </span>
            </div>
          ) : (
            ""
          )}

          <div className="space-y-4 mt-3">
            <div
              className="shadow-lg rounded-lg p-4"
              style={{ backgroundColor: "rgb(24, 36, 80)" }}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h6 className="text-xs font-bold text-gray-400">
                    Payment Method
                  </h6>
                  <span className="text-base font-bold text-primary">
                    {paymentSettings.data.preferredMethod == null
                      ? "N-A"
                      : paymentSettings.data.preferredMethod.methodName}
                  </span>
                </div>
                <div>
                  {paymentSettings.data.preferredMethod != null ? (
                    <>
                      {paymentSettings.data.preferredMethod.methodName ===
                        "Paytm" && (
                        <img src={paytmIcon} width={30} alt="Paytm" />
                      )}
                      {paymentSettings.data.preferredMethod.methodName ===
                        "PayPal" && (
                        <img
                          src={paypalIcon}
                          width={60} // Change width as needed for PayPal
                          alt="PayPal"
                        />
                      )}
                      {paymentSettings.data.preferredMethod.methodName ===
                        "UPI" && (
                        <img
                          src={upiIcon}
                          width={40} // Change width as needed for PayPal
                          alt="PayPal"
                        />
                      )}
                    </>
                  ) : (
                    "No-Image"
                  )}
                </div>
              </div>
            </div>

            <div
              className="shadow-lg rounded-lg p-4"
              style={{ backgroundColor: "rgb(24, 36, 80)" }}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h6 className="text-xs font-bold text-gray-400">
                    Payment Address
                  </h6>
                  <span className="text-base font-bold text-primary">
                    {paymentSettings.data.preferredMethod == null
                      ? "N-A"
                      : paymentSettings.data.preferredMethod.paymentAddress}
                  </span>
                </div>
                <div>
                  <img src={walletIcon} width={30} alt="Wallet Icon" />
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center mt-4">
              <h5 className="text-lg font-bold text-white mb-0 inline-flex items-center">
                Payment Methods
                <img
                  src={fireIcon}
                  width={20}
                  className="ml-2"
                  alt="Fire Icon"
                />
              </h5>
              <a className="text-blue-500 no-underline" href="/statistics">
                View report
              </a>
            </div>

            {updateMethod && showAlert && (
              <div className="mt-3">
                <div
                  className="px-4 py-3 rounded relative mb-3"
                  role="alert"
                  style={{
                    backgroundColor:
                      updateMethod.status === "success"
                        ? "rgb(68 118 67)"
                        : "rgb(32 61 171)",
                  }}
                >
                  <span className="block sm:inline">
                    {updateMethod.message}
                  </span>
                </div>
              </div>
            )}

            <form method="POST" className="mt-6 container">
              <div className="mb-4">
                <div className="input-group custom-placeholder">
                  <select
                    value={selectedMethod}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg support-border text-white"
                    style={{ backgroundColor: "rgb(14, 28, 64)" }}
                  >
                    <option className="text-gray-400" value={0}>
                      Select Method
                    </option>
                    {paymentSettings.data.availableMethods.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-4 custom-placeholder">
                <textarea
                  value={paymentAddress}
                  onChange={(e) => setPaymentAddress(e.target.value)}
                  className="w-full px-4 py-2 support-border rounded-lg bg-transparent text-white"
                  placeholder="Enter your selected payment method details here..."
                  rows="4"
                  spellCheck="false"
                  name="description"
                ></textarea>
              </div>
              <button
                disabled={loading}
                onClick={handleMethodUpdate}
                type="button"
                className="flex items-ce  nter justify-center w-full px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-full hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 no-underline"
              >
                {loading && (
                  <span
                    className="spinner-grow spinner-grow-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Update Method
                <img
                  src={walletIcon}
                  width={15}
                  className=" ml-1"
                  alt="Wallet Icon"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div
          className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 p-4 ${
            isModalOpen ? "modal-enter" : "modal-exit"
          }`}
        >
          <div
            className="rounded-lg shadow-lg p-6 transform transition-transform duration-300 ease-in-out"
            style={{ backgroundColor: "rgb(24, 36, 80)" }}
          >
            <div>
              <h4 className="text-sm font-semibold mb-2">
                Are you sure you want to delete your account?
              </h4>
              <p className="text-center text-sm text-gray-400 mb-3">
                This action will log you out of your current session.
              </p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAccount}
                className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileTabs;
