import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/Support.css"; // Import custom CSS for hiding scrollbars

import support01Icon from "../images/img/dashboard/support_01.svg";
import support02Icon from "../images/img/dashboard/support_02.svg";
import fireIcon from "../images/img/dashboard/fire.svg";

const HelpCenter = ({ onClose }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [isExiting, setIsExiting] = useState(false);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is Daily Captcha Work?",
      answer:
        "Daily Captcha Work is a platform where users can earn money by solving captchas daily.",
    },
    {
      question: "How do I withdraw my earnings?",
      answer:
        "You can request a withdrawal from your account Payout once you reach the minimum threshold.",
    },
    {
      question: "What payment methods are supported?",
      answer:
        "We support various payment methods including Paytm, Bank Transfer, UPI, Bitcoin, PayPal, and USDT.",
    },
    {
      question: "How can I contact support?",
      answer:
        "You can contact support through our live chat support or by emailing support@dailycaptchawork.com.",
    },
    {
      question: "DCW have Telegram channel and group?",
      answer: (
        <>
          You can connect with the DCW team at{" "}
          <a
            href="https://t.me/dailycaptchaworkchannel"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram Channel
          </a>{" "}
          or{" "}
          <a
            href="https://t.me/dailycaptchaworkgroup"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram Group
          </a>{" "}
          here.
        </>
      ),
    },
    {
      question: "How can I change my password?",
      answer: (
        <>
          You can change your password by doing simple steps.
          <br /> Click <a href="/auth/change-password">here</a> to change your
          password.
        </>
      ),
    },
    {
      question: "Where I can see my available balance?",
      answer: <>You can see your available balance on the Home page.</>,
    },
    {
      question: "How can I delete my account?",
      answer: (
        <>Just go to the profile page and request for a account deletion.</>
      ),
    },
    {
      question: "How many days take a payment to receive?",
      answer: (
        <>
          We will clear all the payments in 2 days. It can take more time if
          there is an issue with the financial team.
        </>
      ),
    },
    {
      question: "Is my account safe and secure at DCW?",
      answer: <>Yes your account is 100% safe.</>,
    },
    {
      question: "How can I change my payment method?",
      answer: <>You can change your payment method from the profile.</>,
    },
    {
      question: "Are there any fees for withdrawing earnings?",
      answer: (
        <>
          There may be small processing fees depending on the payment method you
          choose.
        </>
      ),
    },
    {
      question: "How do I change my account details?",
      answer: <>You can update your account details by going to the profile.</>,
    },
    {
      question: "Can I use multiple accounts?",
      answer: (
        <>
          No, each user is allowed to create only one account. Multiple accounts
          will lead to suspension.
        </>
      ),
    },
    {
      question: "What are the rules for captcha solving?",
      answer: (
        <>
          Please ensure you solve captchas accurately and within the given time
          frame. Do not use automated tools as it is against our policies.
        </>
      ),
    },
  ];

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
    }, 300); // Adjust the timeout to match your exit transition duration
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 ${
        isExiting ? "modal-exit" : "modal-enter"
      }`}
    >
      <div
        className="w-full max-w-2xl mx-4 p-4 rounded-lg shadow-lg relative"
        style={{ backgroundColor: "rgb(24, 36, 80)" }}
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-white hover:text-blue-900 text-2xl"
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-6 text-center">Help Center</h2>
        <div className="space-y-4 max-h-80 overflow-y-auto hide-scrollbar">
          {faqs.map((faq, index) => (
            <div key={index} className="support-border rounded-lg">
              <button
                className="w-full text-left px-4 py-2 text-sm font-medium focus:outline-none flex justify-between items-center"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                <span className="ml-2">{openIndex === index ? "-" : "+"}</span>
              </button>
              {openIndex === index && (
                <div
                  className="px-4 py-2"
                  style={{ backgroundColor: "#343e87" }}
                >
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SupportPage = ({ tickets, userDetails, refreshTickets }) => {
  const [showForm, setShowForm] = useState(false);
  const [isHelpCenterOpen, setIsHelpCenterOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [raiseTickets, setRaiseTickets] = useState(null);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [showAlert, setShowAlert] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    if (userDetails) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/raiseTicket`;

        const response = await axios.post(
          apiUrl,
          {
            userId: userDetails.user_data.id,
            subject: subject,
            description: description,
          },
          { withCredentials: true }
        );
        setRaiseTickets(response.data);

        if (response.data.status === "success") {
          setShowForm(false);
          setSubject("");
          setDescription("");
          refreshTickets();
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const handleRaiseTicket = () => {
    setLoading(true); // Start loading
    setShowAlert(true);
    fetchData();
  };

  useEffect(() => {
    if (raiseTickets) {
      // hide alert after 3 seconds
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [raiseTickets]);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleToggleForm = () => {
    setShowForm(!showForm);
  };

  const openHelpCenter = (e) => {
    e.preventDefault();
    setIsHelpCenterOpen(true);
  };

  const closeHelpCenter = () => {
    setIsHelpCenterOpen(false);
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
  };

  if (error) {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-4 p-2">
      <div className="flex justify-between">
        <div className="w-1/2 p-2">
          <a
            href="help-center"
            onClick={openHelpCenter}
            className="block rounded-lg shadow-lg p-3 text-center transition duration-300 no-underline"
            style={{ backgroundColor: "rgb(24, 36, 80)" }}
          >
            {/* SVG Icon */}
            <div className="d-flex justify-content-center">
              <img src={support01Icon} width={25} alt="Support Icon" />
            </div>
            <h6 className="mt-3 text-white font-bold">Payment</h6>
          </a>
        </div>
        <div className="w-1/2 p-2">
          <a
            href="help-center"
            onClick={openHelpCenter}
            className="block rounded-lg shadow-lg p-3 text-center transition duration-300 no-underline"
            style={{ backgroundColor: "rgb(24, 36, 80)" }}
          >
            {/* SVG Icon */}
            <div className="d-flex justify-content-center">
              <img src={support02Icon} width={25} alt="Support Icon" />
            </div>
            <h6 className="mt-3 text-white font-bold">Account</h6>
          </a>
        </div>
      </div>

      <div className="container ticket-container">
        <button
          onClick={handleToggleForm}
          type="button"
          className="w-full px-4 py-2 mt-4 border-2 border-white text-white rounded-sm hover:bg-gray-700 hover:text-gray-400 transition duration-300"
        >
          {showForm ? "Close Ticket" : "Create Ticket"}
        </button>
      </div>

      {raiseTickets && showAlert && (
        <div className="container mt-3">
          <div
            className="px-4 py-3 rounded relative mb-3"
            role="alert"
            style={{
              backgroundColor:
                raiseTickets.status === "success"
                  ? "rgb(68 118 67)"
                  : "rgb(32 61 171)",
            }}
          >
            <span className="block sm:inline">{raiseTickets.message}</span>
          </div>
        </div>
      )}

      {showForm && (
        <form method="POST" className="mt-6 container">
          <div className="mb-4">
            <div className="input-group custom-placeholder">
              <input
                type="text"
                className="w-full px-4 py-2 rounded-lg support-border bg-transparent text-white"
                placeholder="Subject..."
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-4 custom-placeholder">
            <textarea
              className="w-full px-4 py-2 rounded-lg support-border bg-transparent text-white"
              placeholder="Describe here about your problem..."
              rows="4"
              spellCheck="false"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <button
            type="button"
            onClick={handleRaiseTicket}
            disabled={loading} // Disable button while loading
            className="flex items-center justify-center w-full px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-full hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 no-underline"
          >
            {loading && (
              <span
                className="spinner-grow spinner-grow-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Submit Ticket
            <img src={fireIcon} width={15} className="ml-1" alt="Fire Icon" />
          </button>
        </form>
      )}

      <div className="container mt-5">
        <div className="flex justify-between items-center mb-4">
          <h5 className="text-lg font-bold text-white mb-0 inline-flex items-center">
            Tickets
            <img src={fireIcon} width={20} className="ml-2" alt="Fire Icon" />
          </h5>
          <a className="text-blue-500 no-underline" href="/statistics">
            View report
          </a>
        </div>

        {tickets.status === "success" && tickets.data.length > 0 ? (
          <div className="space-y-4">
            {tickets.data.map((ticket, index) => (
              <div key={index} className="support-border rounded-lg">
                <button
                  className="w-full text-left px-4 py-2 text-sm font-medium focus:outline-none flex justify-between items-center"
                  onClick={() => toggleFAQ(index)}
                >
                  <small
                    className={`text-sm mr-1 text ${
                      ticket.ticket_status === 1
                        ? "text-orange-400"
                        : "text-green-500"
                    } `}
                  >
                    {ticket.ticket_status === 1 ? "Pending" : "Solved"}
                  </small>
                  <p className="text-left w-full mb-0 ">
                    {ticket.ticket_subject}
                  </p>
                  <span className="ml-2">
                    {openIndex === index ? "-" : "+"}
                  </span>
                </button>
                {openIndex === index && (
                  <div
                    className="px-4 py-2"
                    style={{ backgroundColor: "#343e87" }}
                  >
                    <p className="font-bold text-gray-400 text-left">
                      Admin Response
                    </p>
                    {ticket.admin_response}
                    <p className="font-bold text-gray-400 text-left mt-2">
                      Ticket Date: {formatDate(ticket.ticket_created)}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div
            className="px-4 py-3 rounded relative"
            role="alert"
            style={{ backgroundColor: "rgb(24 36 80)" }}
          >
            <span className="block sm:inline">
              {/* Handle the case when there are no tickets or the status is not success */}
              {tickets.status !== "success" ? (
                <p>
                  There was an error fetching the tickets. Please try again
                  later.
                </p>
              ) : (
                <p>No tickets found.</p>
              )}
            </span>
          </div>
        )}
      </div>

      {isHelpCenterOpen && <HelpCenter onClose={closeHelpCenter} />}
    </div>
  );
};

export default SupportPage;
