import React, { useEffect, useState } from 'react';
import axios from "axios";

import DashboardHeader from './DashboardHeader';
import Welcome from './Welcome';
import Footer from './Footer';
import ProfileTabs from './ProfileTabs';
import LoadingScreen from './utils/LoadingScreen';

const Profile = ({userDetails}) => {
  const [personal, setPersonal] = useState(null);
  const [paymentSettings, setPaymentSettings] = useState(null);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {

      const apiUrl = `${process.env.REACT_APP_API_URL}/api/getProfile`;

      const response = await axios.post(
        apiUrl,
        { userId: userDetails.user_data.id },
        { withCredentials: true }
      );
      setPersonal(response.data);
    } catch (error) {
      setError("An error occurred while fetching data.");
    }
  };

  const fetchPaymentSettings = async () => {
    try {

      const apiUrl = `${process.env.REACT_APP_API_URL}/api/paymentSettings`;

      const response = await axios.post(
        apiUrl,
        { userId: userDetails.user_data.id },
        { withCredentials: true }
      );
      setPaymentSettings(response.data);
    } catch (error) {
      setError("An error occurred while fetching data.");
    }
  };

  useEffect(() => {
    if (userDetails) {
      fetchData();
      fetchPaymentSettings();
    }
  }, [userDetails]);

  if (!personal || !paymentSettings) {
    return <LoadingScreen />;
  }

  if (error && personal.status !== 'success') {
    return (
      <div
        className="px-4 py-3 rounded relative"
        role="alert"
        style={{ backgroundColor: "rgb(24 36 80)" }}
      >
        <span className="block sm:inline text-white">{error}</span>
      </div>
    );
  }

  return (
    <div className="min-h-screen text-white" style={{backgroundColor: 'rgb(14 28 64)', paddingBottom: '70px'}}>
      <main className="main-wrapper">
        <div className="main-container">
          <DashboardHeader/>
          <Welcome userDetails={userDetails}/>
          <ProfileTabs personal={personal} paymentSettings={paymentSettings} userDetails={userDetails} refreshPersonalDetails={fetchData} refreshPaymentSettings={fetchPaymentSettings}/>
          <Footer/>
        </div>
      </main>
    </div>
  );
};

export default Profile;
