import React, { useState, useEffect } from "react";
import noBgLogo from "../images/img/dashboard/logo-white.png";
import { Link, useLocation } from "react-router-dom";
import LoadingScreen from "../dashboard/utils/LoadingScreen";

const Header = () => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Simulate loading process
    const loadPage = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };

    loadPage();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <nav className="bg-blue-900/10 text-white shadow-2xl fixed w-full z-50 backdrop-blur-md">
        <div className="flex items-center justify-between py-4 px-4 sm:px-6 lg:px-8">
          {/* Logo Section */}
          <div className="text-xl md:text-2xl font-bold">
            <a href="/">
              <img src={noBgLogo} alt="Logo" className="h-8 w-auto md:h-10" />
            </a>
          </div>

          {/* Desktop Navigation Items */}
          <div className="hidden md:flex justify-center space-x-4 lg:space-x-6">
            {isHomePage ? (
              <>
                <a
                  href="#home"
                  onClick={handleScroll}
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  Home
                </a>
                <a
                  href="#features"
                  onClick={handleScroll}
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  Our Features
                </a>
                <a
                  href="#howtowork"
                  onClick={handleScroll}
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  How to Work
                </a>
                <a
                  href="#paymentproofs"
                  onClick={handleScroll}
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  Payment Proofs
                </a>
                <a
                  href="#contactus"
                  onClick={handleScroll}
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  Contact Us
                </a>
              </>
            ) : (
              <>
                <Link
                  to="/"
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  Home
                </Link>
                <Link
                  to="/#features"
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  Our Features
                </Link>
                <Link
                  to="/#howtowork"
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  How to Work
                </Link>
                <Link
                  to="/#paymentproofs"
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  Payment Proofs
                </Link>
                <Link
                  to="/#contactus"
                  className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
                >
                  Contact Us
                </Link>
              </>
            )}
            <a
              href="https://publisher.dailycaptchawork.com"
              className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200 text-sm lg:text-base"
            >
              Become a Publisher
            </a>
          </div>

          {/* Desktop Buttons */}
          <div className="hidden md:flex justify-end space-x-4">
            <a
              href="/auth/login"
              className="px-6 md:px-8 lg:px-16 py-2 rounded-md text-white no-underline font-bold transition-all duration-200 lato-bold login-home-btn bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-sm lg:text-base"
            >
              Login
            </a>
            <a
              href="/auth/create-account"
              className="px-6 md:px-8 lg:px-16 py-2 rounded-md text-white bg-gradient-to-r from-green-500 to-green-700 font-bold hover:from-green-600 hover:to-green-800 no-underline transition-all duration-200 lato-bold join-now-btn text-sm lg:text-base"
            >
              Join Now
            </a>
          </div>

          {/* Mobile Navigation Toggle */}
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleNavbar}
              className="text-white focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                ></path>
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Dropdown Menu */}
        <div
          className={`md:hidden ${isOpen ? "block" : "hidden"} bg-[#050613]`}
        >
          <div className="flex flex-col space-y-2 p-4">
            <Link
              to="/"
              className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200"
            >
              Home
            </Link>
            <Link
              to="/#howtowork"
              className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200"
            >
              How to Work
            </Link>
            <Link
              to="/#features"
              className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200"
            >
              Features
            </Link>
            <a
              href="https://publisher.dailycaptchawork.com"
              className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200"
            >
              Become a Publisher
            </a>
            <Link
              to="/#contactus"
              className="hover:text-blue-400 text-white lato-bold no-underline transition-all duration-200"
            >
              Contact
            </Link>
            <div className="flex space-x-4 mt-4">
              <a
                href="/auth/login"
                className="bg-gradient-to-r from-blue-500 to-blue-700 text-white hover:from-blue-600 hover:to-blue-800 login-home-btn w-full py-2 rounded-md lato-bold transition-all duration-200 no-underline text-center"
              >
                Login
              </a>
              <a
                href="/auth/create-account"
                className="bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 text-white join-now-btn w-full py-2 rounded-md lato-bold transition-all duration-200 no-underline text-center"
              >
                Join Now
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content Wrapper with Blur Effect */}
      <div className="blurred-content-wrapper">
        <div className="main-content">{/* Your main content goes here */}</div>
      </div>
    </>
  );
};

const handleScroll = (event) => {
  event.preventDefault();

  const targetId = event.currentTarget.getAttribute("href").slice(1);
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export default Header;
