import React, { useState, useEffect } from "react";

import noBgLogo from "../images/img/dashboard/logo-white.png";
import leftArrow from "../images/img/home/left-arrow.svg";
import rightArrow from "../images/img/home/right-arrow.svg";

const Testimonals = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState("next");

  const handleNext = () => {
    setAnimationDirection("next");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setAnimationDirection("prev");
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  });

  const testimonials = [
    {
      quote: "Easy to use & customer support is awesome.",
      name: "Karan",
      position: "DCW User",
    },
    {
      quote: "This site is a great way to earn extra cash in my free time!",
      name: "Ayesha",
      position: "DCW User",
    },
    {
      quote:
        "Very reliable platform for making money online through captcha typing.",
      name: "James",
      position: "DCW User",
    },
    {
      quote: "Fantastic tool for earning from home, very easy to use.",
      name: "Anita",
      position: "DCW User",
    },
    {
      quote: "Quick payments and easy captcha tasks, love this site!",
      name: "Rahul",
      position: "DCW User",
    },
    {
      quote: "Reliable and effective, highly recommend to everyone.",
      name: "Sarah",
      position: "DCW User",
    },
    {
      quote: "Perfect for daily use, never had any issues.",
      name: "Ahmed",
      position: "DCW User",
    },
    {
      quote: "Great way to make some extra money during my free time.",
      name: "Maria",
      position: "DCW User",
    },
    {
      quote: "Daily Captcha Work is an amazing platform to earn online.",
      name: "John",
      position: "DCW User",
    },
    {
      quote: "Excellent support and easy-to-use platform.",
      name: "Fatima",
      position: "DCW User",
    },
    {
      quote: "Very user-friendly interface and timely payments.",
      name: "Linda",
      position: "DCW User",
    },
    {
      quote: "Best way to make extra money online, love it!",
      name: "Vikram",
      position: "DCW User",
    },
    // Add more testimonials as needed
  ];

  return (
    <section className="mx-auto px-2 sm:px-6 lg:px-8 py-12 sm:py-24">
      <div className="mb-20 mx-auto max-w-2xl lg:max-w-none text-center space-y-4">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
          What Our Users Say
        </h2>
        <p className="text-lg leading-8 text-gray-300">
          Join thousands of satisfied users who trust Daily Captcha Work. Hear
          about their success and experiences with our platform!
        </p>
      </div>
      <div className="mx-auto">
        <div className="text-center relative bg-white/10 p-8 rounded-xl shadow-lg">
          <div className="overflow-hidden relative h-64">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-transform duration-700 ease-in-out transform ${
                  currentIndex === index
                    ? "translate-x-0 opacity-100"
                    : animationDirection === "next"
                    ? "translate-x-full opacity-0"
                    : "translate-x-[-100%] opacity-0"
                }`}
              >
                <figure className="max-w-screen-md mx-auto">
                  <svg
                    className="h-12 mx-auto mb-6 text-gray-400"
                    viewBox="0 0 24 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                      fill="currentColor"
                    />
                  </svg>
                  <blockquote className="text-2xl font-semibold text-gray-900 dark:text-white transition-all duration-500">
                    "{testimonial.quote}"
                  </blockquote>
                  <figcaption className="flex items-center justify-center mt-8 space-x-4">
                    <img
                      className="w-24 rounded-full shadow-lg"
                      src={noBgLogo}
                      alt={testimonial.name}
                    />
                    <div className="h-12 border-l border-gray-300 dark:border-gray-600"></div>
                    <div className="text-left pl-4">
                      <div className="text-lg font-medium text-gray-900 dark:text-white">
                        {testimonial.name}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {testimonial.position}
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>

          <div className="flex justify-center mt-1 space-x-6">
            <img
              width={60}
              onClick={handlePrev}
              src={leftArrow}
              alt="Left Arrow"
              className="p-3 text-white"
            />
            <img
              width={60}
              src={rightArrow}
              onClick={handleNext}
              alt="Next Arrow"
              className="p-3 text-white"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonals;
