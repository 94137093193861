import React from "react";

const Work = () => {
  return (
    <div className="container mx-auto mt-4">
      <div className="rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 m-1">
        <div className="rounded-t-lg bg-white pt-2 pb-2 pl-4">
          <h3 className="text-lg font-bold text-dark mb-0">Captcha Work</h3>
        </div>
        <div
          style={{ backgroundColor: "rgb(24 36 80)" }}
          className="p-6 rounded-b-lg"
        >
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            To start your work, simply click on the button below. This will take
            you to the work section where you can begin your work.
          </p>
          <p className="text-gray-300">Happy working!</p>
          <a
            href="work"
            className="flex items-center justify-center w-full px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-full hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 no-underline"
          >
            Start Work
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Work;
