import React, { useState, useEffect } from 'react';

const Typewriter = ({ texts, typingSpeed }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Determine current text index
      const currentTextIndex = currentIndex % texts.length;
      const currentText = texts[currentTextIndex];

      // Check if all characters are typed
      if (displayText === currentText) {
        clearInterval(interval);
        setTimeout(() => {
          // Clear display text after a delay
          setDisplayText('');
          setCurrentIndex(prevIndex => prevIndex + 1);
        }, 1000); // Adjust delay before clearing text and moving to next
      } else {
        // Add next character to display text
        setDisplayText(prevText => currentText.slice(0, prevText.length + 1));
      }
    }, typingSpeed); // Adjust typing speed here (milliseconds)

    return () => clearInterval(interval); // Clean up on unmount
  }, [texts, typingSpeed, displayText, currentIndex]);

  return (
    <span>{displayText}</span>
  );
};

export default Typewriter;