import React, { useEffect, useRef } from 'react';
import useAdLoadScript from './useAdLoadScript'; // Import the custom hook

const BitmediaAd = () => {
  const adContainerRef = useRef(null);

  // Load the ad script using the custom hook
  useAdLoadScript('https://cdn.bmcdn4.com/js/6056f9f2b379eae1ffe2376f.js?v=' + new Date().getTime());

  useEffect(() => {
    const adContainer = adContainerRef.current;

    // Create the ad HTML if not already present
    if (adContainer && !adContainer.hasChildNodes()) {
      adContainer.innerHTML = `
        <div class='bitmediaad text-center'>
          <ins class="6056f9f2b379eae1ffe2376f" style="display:inline-block;width:300px;height:250px;"></ins>
        </div>
      `;
    }

    // Cleanup on component unmount to prevent memory leaks
    return () => {
      if (adContainer) {
        adContainer.innerHTML = '';
      }
    };
  }, []);

  return (
    <div ref={adContainerRef}></div>
  );
};

export default BitmediaAd;
